@font-face {
    font-family: 'IconFont';
    src:  url('../../dist/fonts/iconfont.eot?51idit');
    src:  url('../../dist/fonts/iconfont.eot?51idit#iefix') format('embedded-opentype'),
    url('../../dist/fonts/iconfont.ttf?51idit') format('truetype'),
    url('../../dist/fonts/iconfont.woff?51idit') format('woff'),
    url('../../dist/fonts/iconfont.svg?51idit#iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'IconFont' !important;
    speak: none;
    font-size: 1em;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    vertical-align: middle;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icon-search: "\e907";
$icon-soundoff: "\e905";
$icon-soundon: "\e906";
$icon-tick: "\e904";
$icon-play: "\e902";
$icon-stop: "\e903";
$icon-highcontrast: "\e901";
$icon-close: "\e900";


[class*='icon-']{
    display: inline-block;
}

.icon-close {
    &:before {
        content: $icon-close;
    }
}

.icon-tick {
  &:before {
    content: $icon-tick;
  }
}

.icon-highcontrast {
    margin-right: .5em;

    &:before {
        content: $icon-highcontrast;
    }
}

.icon-play {
  &:after {
    content: $icon-play;
  }
}

.icon-stop {
  &:after {
    content: $icon-stop;
  }
}

.icon-soundoff {
  &:before {
    content: $icon-soundoff;
  }
}
.icon-soundon {
  &:before {
    content: $icon-soundon;
  }
}

.icon-search {
  &:before {
    content: $icon-search;
  }
}

.icon-carret{
    position: relative;
    height: 1em;
    width: 1em;

    &:before{
        content: '';
        display: block;
        position: absolute;
        height: .35em;
        width: .35em;
        border: .35em solid transparent;
        border-bottom: .35em solid #DEE2E6;
    }
}
