@charset "UTF-8";
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4g03OwRmPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM3OwRmPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4mE3OwRmPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4rQwOwRmPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4vowOwRmPg.ttf) format('truetype');
}

@font-face{
    font-family:'Trajan';
    src:url("../../dist/fonts/TrajanPro3-Regular.eot");
    src:url("../../dist/fonts/TrajanPro3-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../dist/fonts/TrajanPro3-Regular.woff2") format("woff2"),
    url("../../dist/fonts/TrajanPro3-Regular.woff") format("woff"),
    url("../../dist/fonts/TrajanPro3.otf") format("opentype"),
    url("../../dist/fonts/TrajanPro3-Regular.ttf") format("truetype"),
    url("../../dist/fonts/TrajanPro3-Regular.svg#") format("svg");
    font-weight:400;
    font-style:normal;
    font-stretch:normal
}

@font-face{
    font-family:'Trajan';
    src:url("../../dist/fonts/TrajanPro3-Bold.eot");
    src:url("../../dist/fonts/TrajanPro3-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../dist/fonts/TrajanPro3-Bold.woff2") format("woff2"),
    url("../../dist/fonts/TrajanPro3-Bold.woff") format("woff"),
    url("../../dist/fonts/TrajanPro3.otf") format("opentype"),
    url("../../dist/fonts/TrajanPro3-Bold.ttf") format("truetype"),
    url("../../dist/fonts/TrajanPro3-Bold.svg#") format("svg");
    font-weight:700;
    font-style:bold;
    font-stretch:normal
}

@font-face {
    font-family: 'IconFont';
    src:  url('../../dist/fonts/iconfont.eot?51idit');
    src:  url('../../dist/fonts/iconfont.eot?51idit#iefix') format('embedded-opentype'),
    url('../../dist/fonts/iconfont.ttf?51idit') format('truetype'),
    url('../../dist/fonts/iconfont.woff?51idit') format('woff'),
    url('../../dist/fonts/iconfont.svg?51idit#iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'IconFont' !important;
    speak: none;
    font-size: 1em;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    vertical-align: middle;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icon-search: "\e907";
$icon-soundoff: "\e905";
$icon-soundon: "\e906";
$icon-tick: "\e904";
$icon-play: "\e902";
$icon-stop: "\e903";
$icon-highcontrast: "\e901";
$icon-close: "\e900";


[class*='icon-']{
    display: inline-block;
}

.icon-close {
    &:before {
        content: $icon-close;
    }
}

.icon-tick {
  &:before {
    content: $icon-tick;
  }
}

.icon-highcontrast {
    margin-right: .5em;

    &:before {
        content: $icon-highcontrast;
    }
}

.icon-play {
  &:after {
    content: $icon-play;
  }
}

.icon-stop {
  &:after {
    content: $icon-stop;
  }
}

.icon-soundoff {
  &:before {
    content: $icon-soundoff;
  }
}
.icon-soundon {
  &:before {
    content: $icon-soundon;
  }
}

.icon-search {
  &:before {
    content: $icon-search;
  }
}

.icon-carret{
    position: relative;
    height: 1em;
    width: 1em;

    &:before{
        content: '';
        display: block;
        position: absolute;
        height: .35em;
        width: .35em;
        border: .35em solid transparent;
        border-bottom: .35em solid #DEE2E6;
    }
}

html.js .animated[data-animation="fade-in-simple"] {
  opacity: 0;
  transition: transform 1.5s ease, opacity 1.5s ease;
  transform: scale(0.9); }

html.js .animated.fade-in-simple {
  opacity: 1;
  transform: scale(1); }
  html.js .animated.fade-in-simple:before, html.js .animated.fade-in-simple:after {
    width: calc(50% - 4rem) !important; }

html.js .animated[data-animation="fade-in"] {
  opacity: 0;
  transition: transform 1.5s ease, opacity 1.5s ease;
  transform: translateY(100px); }

html.js .animated.fade-in {
  opacity: 1;
  transform: translateY(0); }
  html.js .animated.fade-in:before, html.js .animated.fade-in:after {
    width: calc(50% - 4rem) !important; }

html.js .animated[data-animation="fade-in-left"] {
  opacity: 0;
  transition: transform 1.5s ease, opacity 1.5s ease;
  transform: translateX(200px); }

html.js .animated.fade-in-left {
  opacity: 1;
  transform: translateX(0); }
  html.js .animated.fade-in-left:before, html.js .animated.fade-in-left:after {
    width: calc(50% - 4rem) !important; }

html.js .animated[data-animation="fade-in-right"] {
  opacity: 0;
  transition: transform 1.5s ease, opacity 1.5s ease;
  transform: translateX(-200px); }

html.js .animated.fade-in-right {
  opacity: 1;
  transform: translateX(0); }
  html.js .animated.fade-in-right:before, html.js .animated.fade-in-right:after {
    width: calc(50% - 4rem) !important; }

html.js .animated[data-animation="fade-in-top"] {
  opacity: 0;
  transition: transform 1.5s ease, opacity 1.5s ease;
  transform: translateY(-100px); }

html.js .animated.fade-in-top {
  opacity: 1;
  transform: translateY(0); }
  html.js .animated.fade-in-top:before, html.js .animated.fade-in-top:after {
    width: calc(50% - 4rem) !important; }

html.js .page-terme .animated[data-animation="fade-in-simple"],
html.js .page-arte-cultura .animated[data-animation="fade-in-simple"],
html.js .page-food-wine .animated[data-animation="fade-in-simple"] {
  opacity: 0;
  transition: transform 1.5s ease, opacity 1.5s ease;
  transform: scale(0.9); }

html.js .page-terme .animated.fade-in-simple,
html.js .page-arte-cultura .animated.fade-in-simple,
html.js .page-food-wine .animated.fade-in-simple {
  opacity: 1;
  transform: scale(1); }
  html.js .page-terme .animated.fade-in-simple:before, html.js .page-terme .animated.fade-in-simple:after,
  html.js .page-arte-cultura .animated.fade-in-simple:before,
  html.js .page-arte-cultura .animated.fade-in-simple:after,
  html.js .page-food-wine .animated.fade-in-simple:before,
  html.js .page-food-wine .animated.fade-in-simple:after {
    width: calc(50% - 4rem) !important; }

html.js .page-terme .animated.fade-in,
html.js .page-arte-cultura .animated.fade-in,
html.js .page-food-wine .animated.fade-in {
  opacity: 1;
  transform: scale(1); }
  html.js .page-terme .animated.fade-in:before, html.js .page-terme .animated.fade-in:after,
  html.js .page-arte-cultura .animated.fade-in:before,
  html.js .page-arte-cultura .animated.fade-in:after,
  html.js .page-food-wine .animated.fade-in:before,
  html.js .page-food-wine .animated.fade-in:after {
    width: 100% !important; }

* {
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  font-family: "Exo", sans-serif; }

h1,
.h1 {
  font-family: "Trajan", serif; }

h2,
.h2 {
  font-family: "Trajan", serif; }

h3,
.h3 {
  font-family: "Trajan", serif; }

h4,
.h4 {
  font-family: "Trajan", serif; }

h5,
.h5 {
  font-family: "Trajan", serif; }

h6,
.h6 {
  font-family: "Trajan", serif; }

.body-overflow-hidden {
  overflow: hidden; }

p {
  margin-bottom: 0; }

* {
  outline: none; }

html {
  scroll-behavior: smooth; }

body #main-content {
  transition: transform 1.2s ease; }

body.menu-active {
  overflow: hidden; }
  body.menu-active #main-content {
    transform: translateY(-10rem); }

.features-list {
  width: 100%; }
  .features-list li {
    padding: 1em;
    width: 50%;
    float: left;
    color: rgba(5, 109, 169, 0.8);
    font-size: .9rem;
    background-color: rgba(5, 109, 169, 0.075);
    border: 0.25em #fff solid; }
    @media (max-width: 767.98px) {
      .features-list li {
        width: 100%; } }
    .features-list li i {
      padding: .35em;
      margin-right: 1em;
      color: #fff;
      font-size: .5rem;
      background-color: #056DA9;
      border-radius: 50%; }

.dropdown-menu {
  padding: .5em;
  min-width: inherit;
  background-color: rgba(0, 0, 0, 0.4);
  border: none;
  box-shadow: none;
  backdrop-filter: blur(0.1em); }
  .dropdown-menu .dropdown-item {
    color: #fff;
    font-size: .8rem;
    letter-spacing: .1em;
    border-left: 3px transparent solid; }
    .dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:active {
      background-color: rgba(255, 255, 255, 0);
      border-color: #fff; }

.language-menu {
  width: fit-content; }
  .language-menu .wpml-ls-legacy-dropdown {
    width: auto;
    color: #adb5bd; }
    .language-menu .wpml-ls-legacy-dropdown a {
      color: inherit;
      border: none; }
      .language-menu .wpml-ls-legacy-dropdown a .wpml-ls-display {
        display: none; }
    .language-menu .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover > a {
      background-color: transparent; }
      .language-menu .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover > a:hover {
        background-color: transparent; }
    .language-menu .wpml-ls-legacy-dropdown .wpml-ls-item-toggle {
      padding: 0 .5em 0 0; }
    .language-menu .wpml-ls-legacy-dropdown li {
      margin: 0;
      background-color: #fff; }
      .language-menu .wpml-ls-legacy-dropdown li a.wpml-ls-item-toggle {
        padding: .5rem 1rem;
        color: inherit;
        font-size: .8rem;
        border: none;
        border-radius: .2em;
        background-color: transparent;
        transition: background-color 0.4s cubic-bezier(0.4, -0.55, 0.55, 1.5); }
        .language-menu .wpml-ls-legacy-dropdown li a.wpml-ls-item-toggle:after {
          content: "";
          display: inline-block;
          margin-left: .25em;
          vertical-align: .255em;
          border-top: .3em solid;
          border-right: .3em solid transparent;
          border-bottom: 0;
          border-left: .3em solid transparent;
          right: .25em; }
        .language-menu .wpml-ls-legacy-dropdown li a.wpml-ls-item-toggle:hover {
          background-color: transparent; }
      .language-menu .wpml-ls-legacy-dropdown li:hover {
        background-color: #fff; }
    .language-menu .wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
      display: block;
      padding: .5rem 0;
      margin: .125rem 0 0;
      right: auto;
      min-width: -webkit-fit-content;
      letter-spacing: .1em;
      background-color: #fff;
      border-top-color: #dee2e6;
      box-shadow: 0 0 0.5em 0 rgba(173, 181, 189, 0.2);
      z-index: 1000; }
      .language-menu .wpml-ls-legacy-dropdown .wpml-ls-sub-menu li {
        margin: 0;
        border: none; }

.wpml-ls-legacy-dropdown {
  display: none; }

header {
  position: absolute;
  padding: 1rem;
  width: 100%;
  color: #fff;
  z-index: 9;
  top: 0;
  transition: .5s all;
  user-select: none;
  transition: box-shadow .7s ease; }
  header:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    background-color: #fff;
    transition: height .7s ease;
    z-index: -1; }
  @media (max-width: 767.98px) {
    header nav .nav-link {
      color: #fff; } }
  header .back-link {
    color: #fff;
    font-size: .8rem;
    font-weight: 600;
    letter-spacing: .15em;
    text-transform: uppercase; }
    header .back-link a {
      padding: .5rem 1rem;
      color: inherit;
      text-decoration: none;
      border-radius: .2em;
      transition: background-color .4s ease; }
      @media (max-width: 767.98px) {
        header .back-link a {
          padding: 0; } }
      header .back-link a:hover {
        background-color: rgba(255, 255, 255, 0.2); }
        @media (max-width: 767.98px) {
          header .back-link a:hover {
            background-color: transparent; } }
    header .back-link img {
      width: 1rem;
      vertical-align: sub; }
      @media (max-width: 767.98px) {
        header .back-link img {
          width: 1.8rem; } }
  header .logo-container {
    display: inline-block; }
    header .logo-container .logo {
      max-width: 40px;
      width: 100%; }
  header nav {
    font-family: 'Exo', sans-serif;
    text-transform: uppercase; }
    header nav .nav-link {
      color: #fff;
      transition: color .7s ease; }
  header .top-header {
    font-size: .8em; }
    header .top-header .nav-item {
      border: none;
      border-radius: .2em;
      transition: background-color 0.4s cubic-bezier(0.4, -0.55, 0.55, 1.5); }
      header .top-header .nav-item:hover {
        background-color: rgba(248, 249, 250, 0.2); }
    header .top-header .wpml-ls-legacy-dropdown {
      width: auto; }
      header .top-header .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover > a {
        color: #fff;
        background-color: transparent; }
      header .top-header .wpml-ls-legacy-dropdown li {
        padding: .65rem 1rem;
        color: #fff;
        border-radius: .2em; }
        header .top-header .wpml-ls-legacy-dropdown li:hover {
          background-color: rgba(248, 249, 250, 0.2); }
        header .top-header .wpml-ls-legacy-dropdown li a {
          padding: 0 1.05em 0 0;
          color: inherit;
          font-size: .8rem;
          border: none;
          border-radius: .2em;
          background-color: transparent;
          transition: background-color 0.4s cubic-bezier(0.4, -0.55, 0.55, 1.5); }
          header .top-header .wpml-ls-legacy-dropdown li a.wpml-ls-item-toggle:after {
            content: "";
            display: inline-block;
            margin-left: .255em;
            vertical-align: .255em;
            border-top: .3em solid;
            border-right: .3em solid transparent;
            border-bottom: 0;
            border-left: .3em solid transparent;
            right: 0; }
          header .top-header .wpml-ls-legacy-dropdown li a:hover {
            color: inherit;
            background-color: transparent; }
          header .top-header .wpml-ls-legacy-dropdown li a .wpml-ls-display {
            display: none; }
      header .top-header .wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
        display: block;
        padding: .5rem 0;
        margin: .125rem 0 0;
        min-width: -webkit-fit-content;
        letter-spacing: .1em;
        border-radius: .25rem;
        background-clip: padding-box;
        background-color: rgba(0, 0, 0, 0.4);
        border: none;
        box-shadow: none;
        backdrop-filter: blur(0.1em);
        z-index: 1000; }
        header .top-header .wpml-ls-legacy-dropdown .wpml-ls-sub-menu li {
          background-color: transparent; }
        header .top-header .wpml-ls-legacy-dropdown .wpml-ls-sub-menu a {
          position: relative;
          padding: .25rem .8rem; }
          header .top-header .wpml-ls-legacy-dropdown .wpml-ls-sub-menu a:hover:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            height: 100%;
            width: 2px;
            background-color: #fff;
            transform: translateY(-50%); }
    header .top-header .highcontrast button {
      text-transform: uppercase;
      background-color: transparent;
      border: none; }
    header .top-header .highcontrast i {
      transition: transform .4s ease; }
      header .top-header .highcontrast i:before {
        line-height: initial; }
    header .top-header .highcontrast:hover i {
      transform: rotate(180deg); }
    header .top-header .cta-contact {
      padding: .8em 1.5em;
      min-width: unset; }
  @media (max-width: 767.98px) {
    header .main-header {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); } }
  header .main-header .main-nav {
    margin-bottom: 0; }
    header .main-header .main-nav .menu {
      margin-bottom: 0;
      font-family: 'Exo', sans-serif;
      font-size: .9rem;
      font-weight: 700;
      letter-spacing: .3em;
      text-transform: uppercase; }
      header .main-header .main-nav .menu .nav-item {
        margin-bottom: 0;
        margin-left: 1rem; }
  header .menu-toggler {
    display: block;
    width: 1rem;
    cursor: pointer; }
    header .menu-toggler .toggler-icon {
      display: block;
      height: 2px;
      width: 100%;
      background-color: #fff;
      transition: width .4s ease; }
      header .menu-toggler .toggler-icon:not(:first-child) {
        margin-top: .2em; }
      header .menu-toggler .toggler-icon:nth-child(2) {
        width: 75%;
        transition: width .4s ease; }
    header .menu-toggler:hover .toggler-icon {
      width: 75%; }
      header .menu-toggler:hover .toggler-icon:nth-child(2) {
        width: 100%; }
  header .menu-closer {
    display: block;
    position: absolute;
    right: 0;
    top: -3rem;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 1.2s ease, opacity 1.2s ease; }
    header .menu-closer:hover {
      transform: translateY(100%) rotate(180deg); }
  header .navbar {
    padding: 0 1rem; }
  header.color-header {
    background-position: 100%;
    box-shadow: 0 0 0.5rem -0.05rem rgba(0, 0, 0, 0.15); }
    header.color-header:after {
      height: 100%; }
    header.color-header nav .nav-link {
      color: #000; }
    header.color-header .menu-toggler .toggler-icon {
      background-color: #000; }
  header #floatingMenuToggler {
    display: flex;
    position: fixed;
    top: 2rem;
    right: 2rem;
    height: 45px;
    width: 45px;
    background-color: rgba(173, 181, 189, 0.5);
    backdrop-filter: blur(0.8rem);
    transform: translateY(-200%);
    transition: transform 1s ease, background-color .4s ease; }
    header #floatingMenuToggler .icon-menu {
      display: block;
      width: 1rem; }
    @media (max-width: 767.98px) {
      header #floatingMenuToggler {
        top: 1rem;
        right: 1rem;
        transform: translateY(0); } }
    header #floatingMenuToggler.active {
      transform: translateY(0); }
  header #btnBackTop {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    transform: translateY(200%);
    transition: transform 1s ease, background-color .4s ease; }
    @media (max-width: 767.98px) {
      header #btnBackTop {
        bottom: 1rem;
        right: 1rem; } }
    header #btnBackTop.active {
      transform: translateY(0); }
      header #btnBackTop.active:hover {
        transform: translateY(-0.5rem); }

#main-navigation {
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 1;
  z-index: 10;
  transform: translateY(100%);
  pointer-events: none;
  transition: 1.2s all; }
  @media (max-width: 991.98px) {
    #main-navigation {
      height: calc(100% - 4rem); } }
  #main-navigation #overlay-filter {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 2s ease;
    cursor: pointer; }
    @media (max-width: 991.98px) {
      #main-navigation #overlay-filter {
        background: rgba(0, 0, 0, 0.85); } }
  #main-navigation #menu-closer {
    position: absolute;
    top: 0;
    right: 3rem;
    transform: translateY(100%);
    z-index: 1;
    cursor: pointer;
    transition: transform 1.6s ease; }
    @media (max-width: 991.98px) {
      #main-navigation #menu-closer {
        right: 1rem; } }
    #main-navigation #menu-closer img {
      pointer-events: none;
      transition: transform .4s ease; }
      @media (max-width: 991.98px) {
        #main-navigation #menu-closer img {
          width: 100%;
          max-width: 40px; } }
    #main-navigation #menu-closer:hover img {
      transform: rotate(90deg); }
  #main-navigation #navigation-content {
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    opacity: 0;
    z-index: 2;
    transition: opacity 2.8s ease;
    transition-delay: opacity 2s ease; }
    #main-navigation #navigation-content .topic-image {
      height: 400px;
      width: 100%;
      max-width: 300px;
      border: 1rem #fff solid;
      border-left: 0;
      opacity: 0;
      transform: translate(-80%, 0);
      transition: opacity 1.2s ease, transform 1.4s ease; }
      @media (max-width: 991.98px) {
        #main-navigation #navigation-content .topic-image {
          position: absolute;
          height: 5rem;
          max-width: 100%;
          border: 1rem #fff solid;
          border-bottom: 0;
          bottom: 0;
          transform: translate(0, 100%);
          transition: all 2s ease;
          transition-delay: transform .25s ease; } }
      #main-navigation #navigation-content .topic-image img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center; }
    #main-navigation #navigation-content .first-column .social-icon {
      margin-bottom: 8rem; }
      #main-navigation #navigation-content .first-column .social-icon a {
        width: 1.75rem;
        display: inline-block; }
        #main-navigation #navigation-content .first-column .social-icon a img {
          width: 100%;
          opacity: .15;
          transition: .4s opacity ease; }
        #main-navigation #navigation-content .first-column .social-icon a:hover img {
          opacity: .35; }
    #main-navigation #navigation-content .first-column .menu-logo {
      width: 100%;
      max-width: 250px;
      border-top: 1px #dee2e6 solid; }
      #main-navigation #navigation-content .first-column .menu-logo img {
        width: 100%; }
    #main-navigation #navigation-content .second-column {
      position: relative; }
      @media (max-width: 991.98px) {
        #main-navigation #navigation-content .second-column {
          position: relative;
          padding-bottom: 4rem;
          height: 100%;
          overflow-y: auto; } }
      #main-navigation #navigation-content .second-column .search-box {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1; }
        #main-navigation #navigation-content .second-column .search-box h6 {
          color: #000;
          font-size: 1rem;
          font-family: "Exo", sans-serif; }
      #main-navigation #navigation-content .second-column .topic-option-menu .language-menu .wpml-ls-item-toggle {
        padding: .5rem 1rem .5em 0; }
      #main-navigation #navigation-content .second-column .topic-option-menu .option-menu {
        border-bottom: 1px #dee2e6 solid; }
        #main-navigation #navigation-content .second-column .topic-option-menu .option-menu a {
          color: #979797;
          font-size: 0.75rem; }
        @media (max-width: 991.98px) {
          #main-navigation #navigation-content .second-column .topic-option-menu .option-menu {
            position: sticky;
            top: 0;
            background-color: #fff;
            z-index: 1; } }
      @media (max-width: 991.98px) {
        #main-navigation #navigation-content .second-column .topic-option-menu .menu-logo {
          max-width: 200px; } }
      #main-navigation #navigation-content .second-column .parent-menu li {
        display: block;
        font-size: 1rem;
        font-weight: 500;
        width: 100%;
        text-align: left;
        margin: .58rem 0;
        text-transform: uppercase;
        opacity: 0;
        transform: translateX(-10%);
        transition: opacity 1s ease, transform 1s ease; }
        @media (min-width: 768px) {
          #main-navigation #navigation-content .second-column .parent-menu li:nth-child(1) {
            transition-delay: 0s; }
          #main-navigation #navigation-content .second-column .parent-menu li:nth-child(2) {
            transition-delay: 0.05s; }
          #main-navigation #navigation-content .second-column .parent-menu li:nth-child(3) {
            transition-delay: 0.1s; }
          #main-navigation #navigation-content .second-column .parent-menu li:nth-child(4) {
            transition-delay: 0.15s; }
          #main-navigation #navigation-content .second-column .parent-menu li:nth-child(5) {
            transition-delay: 0.2s; } }
        @media (max-width: 991.98px) {
          #main-navigation #navigation-content .second-column .parent-menu li {
            transition: margin .4s ease; } }
        #main-navigation #navigation-content .second-column .parent-menu li a {
          color: #000;
          padding: 0;
          letter-spacing: .2em;
          cursor: pointer;
          transition: color .4s ease; }
          @media (max-width: 991.98px) {
            #main-navigation #navigation-content .second-column .parent-menu li a {
              max-height: 1.3em;
              overflow: hidden;
              pointer-events: all;
              transition: max-height .4s ease, padding .4s ease; } }
          #main-navigation #navigation-content .second-column .parent-menu li a.current:hover {
            color: #000; }
          #main-navigation #navigation-content .second-column .parent-menu li a:hover {
            color: #adb5bd; }
      #main-navigation #navigation-content .second-column .parent-menu.disabled li a:not(.current) {
        color: #e9ecef;
        pointer-events: all; }
        #main-navigation #navigation-content .second-column .parent-menu.disabled li a:not(.current):hover {
          color: #495057; }
      @media (max-width: 991.98px) {
        #main-navigation #navigation-content .second-column .parent-menu.disabled li {
          margin-top: 0;
          margin-bottom: 0; }
          #main-navigation #navigation-content .second-column .parent-menu.disabled li a.current {
            padding: 0 0 2.5rem;
            border-bottom: 1px #dee2e6 solid; }
          #main-navigation #navigation-content .second-column .parent-menu.disabled li a:not(.current) {
            max-height: 0; } }
      #main-navigation #navigation-content .second-column .default-menu,
      #main-navigation #navigation-content .second-column .children-menu {
        position: relative; }
        @media (min-width: 768px) {
          #main-navigation #navigation-content .second-column .default-menu,
          #main-navigation #navigation-content .second-column .children-menu {
            height: 100%; } }
        #main-navigation #navigation-content .second-column .default-menu a,
        #main-navigation #navigation-content .second-column .children-menu a {
          color: #000;
          padding: 0;
          letter-spacing: 0.12rem; }
          #main-navigation #navigation-content .second-column .default-menu a img,
          #main-navigation #navigation-content .second-column .children-menu a img {
            margin-right: 10px;
            max-width: 20px; }
          #main-navigation #navigation-content .second-column .default-menu a.parent-link,
          #main-navigation #navigation-content .second-column .children-menu a.parent-link {
            position: relative;
            font-weight: bold;
            cursor: pointer; }
            #main-navigation #navigation-content .second-column .default-menu a.parent-link:after,
            #main-navigation #navigation-content .second-column .children-menu a.parent-link:after {
              content: '';
              display: inline-block;
              position: absolute;
              margin: 0 .25em;
              top: 50%;
              width: 1rem;
              height: 1rem;
              background-image: url("../img/vectors/arrow-back.svg");
              background-size: contain;
              background-position: center;
              border-radius: 50%;
              opacity: 0;
              transform: translateY(-52.5%) rotate(180deg);
              transition: margin .4s ease, opacity .7s ease; }
            #main-navigation #navigation-content .second-column .default-menu a.parent-link:hover:after,
            #main-navigation #navigation-content .second-column .children-menu a.parent-link:hover:after {
              margin: 0 .5em;
              opacity: 1; }
          #main-navigation #navigation-content .second-column .default-menu a.back-link,
          #main-navigation #navigation-content .second-column .children-menu a.back-link {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -.1rem;
            height: 1.2rem;
            width: 1.2rem;
            background-color: #dee2e6;
            border-radius: 50%;
            cursor: pointer; }
            #main-navigation #navigation-content .second-column .default-menu a.back-link i,
            #main-navigation #navigation-content .second-column .children-menu a.back-link i {
              position: absolute;
              height: 8px;
              width: 8px;
              color: #fff;
              font-size: 8px; }
        #main-navigation #navigation-content .second-column .default-menu > ul,
        #main-navigation #navigation-content .second-column .children-menu > ul {
          transition: opacity .7s ease; }
          @media (min-width: 768px) {
            #main-navigation #navigation-content .second-column .default-menu > ul,
            #main-navigation #navigation-content .second-column .children-menu > ul {
              position: absolute; } }
          #main-navigation #navigation-content .second-column .default-menu > ul li,
          #main-navigation #navigation-content .second-column .children-menu > ul li {
            display: block;
            font-size: 0.9rem;
            font-weight: 400;
            width: 100%;
            text-align: left;
            margin: .65rem 0;
            text-transform: uppercase;
            opacity: 0;
            transform: translateX(-10%);
            transition: opacity 1s ease, transform 1s ease; }
            @media (min-width: 768px) {
              #main-navigation #navigation-content .second-column .default-menu > ul li:not([data-menu]):nth-child(1),
              #main-navigation #navigation-content .second-column .children-menu > ul li:not([data-menu]):nth-child(1) {
                transition-delay: 0s; }
              #main-navigation #navigation-content .second-column .default-menu > ul li:not([data-menu]):nth-child(2),
              #main-navigation #navigation-content .second-column .children-menu > ul li:not([data-menu]):nth-child(2) {
                transition-delay: 0.05s; }
              #main-navigation #navigation-content .second-column .default-menu > ul li:not([data-menu]):nth-child(3),
              #main-navigation #navigation-content .second-column .children-menu > ul li:not([data-menu]):nth-child(3) {
                transition-delay: 0.1s; }
              #main-navigation #navigation-content .second-column .default-menu > ul li:not([data-menu]):nth-child(4),
              #main-navigation #navigation-content .second-column .children-menu > ul li:not([data-menu]):nth-child(4) {
                transition-delay: 0.15s; }
              #main-navigation #navigation-content .second-column .default-menu > ul li:not([data-menu]):nth-child(5),
              #main-navigation #navigation-content .second-column .children-menu > ul li:not([data-menu]):nth-child(5) {
                transition-delay: 0.2s; } }
            #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu],
            #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] {
              margin: 0;
              transform: translateX(0);
              transition-delay: 0s;
              pointer-events: all; }
              #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li,
              #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li {
                transform: translateX(0);
                transition: opacity .4s ease, transform .4s ease; }
                @media (min-width: 576px) {
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(1),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(1) {
                    transition-delay: 0s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(2),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(2) {
                    transition-delay: 0.05s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(3),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(3) {
                    transition-delay: 0.1s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(4),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(4) {
                    transition-delay: 0.15s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(5),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(5) {
                    transition-delay: 0.2s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(6),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(6) {
                    transition-delay: 0.25s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(7),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(7) {
                    transition-delay: 0.3s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(8),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(8) {
                    transition-delay: 0.35s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(9),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(9) {
                    transition-delay: 0.4s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(10),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(10) {
                    transition-delay: 0.45s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(11),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(11) {
                    transition-delay: 0.5s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(12),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(12) {
                    transition-delay: 0.55s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(13),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(13) {
                    transition-delay: 0.6s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(14),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(14) {
                    transition-delay: 0.65s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(15),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(15) {
                    transition-delay: 0.7s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(16),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(16) {
                    transition-delay: 0.75s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(17),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(17) {
                    transition-delay: 0.8s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(18),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(18) {
                    transition-delay: 0.85s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(19),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(19) {
                    transition-delay: 0.9s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(20),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(20) {
                    transition-delay: 0.95s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(21),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(21) {
                    transition-delay: 1s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(22),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(22) {
                    transition-delay: 1.05s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(23),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(23) {
                    transition-delay: 1.1s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(24),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(24) {
                    transition-delay: 1.15s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(25),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(25) {
                    transition-delay: 1.2s; }
                  #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu] ul li:nth-child(26),
                  #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu] ul li:nth-child(26) {
                    transition-delay: 1.25s; } }
              #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu].not-active,
              #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu].not-active {
                height: 0;
                opacity: 0;
                transform: translateX(-5%);
                pointer-events: none; }
                #main-navigation #navigation-content .second-column .default-menu > ul li[data-menu].not-active ul li,
                #main-navigation #navigation-content .second-column .children-menu > ul li[data-menu].not-active ul li {
                  transform: translateX(-10%); }
          #main-navigation #navigation-content .second-column .default-menu > ul.not-active,
          #main-navigation #navigation-content .second-column .children-menu > ul.not-active {
            opacity: 0;
            pointer-events: none;
            transform: translateX(10%);
            transition: opacity .4s ease, transform .4s ease; }
            @media (max-width: 767.98px) {
              #main-navigation #navigation-content .second-column .default-menu > ul.not-active,
              #main-navigation #navigation-content .second-column .children-menu > ul.not-active {
                max-height: 0;
                padding: 0;
                margin: 0; } }
            #main-navigation #navigation-content .second-column .default-menu > ul.not-active li,
            #main-navigation #navigation-content .second-column .children-menu > ul.not-active li {
              transform: translateX(10%);
              transition: opacity .7s ease, transform 1s ease; }
              #main-navigation #navigation-content .second-column .default-menu > ul.not-active li[data-menu],
              #main-navigation #navigation-content .second-column .children-menu > ul.not-active li[data-menu] {
                margin: 0;
                transform: translateX(0); }
                @media (max-width: 767.98px) {
                  #main-navigation #navigation-content .second-column .default-menu > ul.not-active li[data-menu] ul,
                  #main-navigation #navigation-content .second-column .children-menu > ul.not-active li[data-menu] ul {
                    max-height: 0;
                    overflow: hidden; } }
                #main-navigation #navigation-content .second-column .default-menu > ul.not-active li[data-menu] ul li,
                #main-navigation #navigation-content .second-column .children-menu > ul.not-active li[data-menu] ul li {
                  transform: translateX(-10%);
                  transition: opacity .7s ease, transform .4s ease; }
          #main-navigation #navigation-content .second-column .default-menu > ul.active,
          #main-navigation #navigation-content .second-column .children-menu > ul.active {
            opacity: 1;
            pointer-events: all; }
            @media (max-width: 767.98px) {
              #main-navigation #navigation-content .second-column .default-menu > ul.active li[data-menu] ul,
              #main-navigation #navigation-content .second-column .children-menu > ul.active li[data-menu] ul {
                max-height: 100%;
                overflow: visible; } }
    #main-navigation #navigation-content .copyright {
      color: #979797;
      transform: translateY(calc(100% + 5rem));
      transition: transform 1.6s ease;
      transition-delay: .4s; }
      #main-navigation #navigation-content .copyright p {
        display: inline-block;
        text-transform: uppercase;
        font-size: 0.75rem; }
      #main-navigation #navigation-content .copyright .services-link {
        display: inline-block; }
        #main-navigation #navigation-content .copyright .services-link a {
          text-transform: uppercase;
          font-size: 0.75rem;
          color: #979797; }
  #main-navigation nav .nav-link {
    color: #000; }
  #main-navigation.active {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all; }
    #main-navigation.active #overlay-filter {
      opacity: 1; }
    #main-navigation.active #menu-closer {
      transform: translateY(calc(-100% - 1rem)); }
    #main-navigation.active #navigation-content {
      opacity: 1; }
      #main-navigation.active #navigation-content .topic-image {
        opacity: 1;
        transform: translate(0, 0); }
        @media (max-width: 991.98px) {
          #main-navigation.active #navigation-content .topic-image {
            transform: translate(0, 100%); }
            #main-navigation.active #navigation-content .topic-image img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: center; }
            #main-navigation.active #navigation-content .topic-image.mobile-active {
              transform: translate(0, 0);
              transition-delay: transform .4s ease; } }
      #main-navigation.active #navigation-content .second-column .parent-menu li {
        opacity: 1;
        transform: translateX(0); }
        @media (min-width: 576px) {
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(1) {
            transition-delay: 0s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(2) {
            transition-delay: 0.25s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(3) {
            transition-delay: 0.5s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(4) {
            transition-delay: 0.75s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(5) {
            transition-delay: 1s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(6) {
            transition-delay: 1.25s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(7) {
            transition-delay: 1.5s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(8) {
            transition-delay: 1.75s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(9) {
            transition-delay: 2s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(10) {
            transition-delay: 2.25s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(11) {
            transition-delay: 2.5s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(12) {
            transition-delay: 2.75s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(13) {
            transition-delay: 3s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(14) {
            transition-delay: 3.25s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(15) {
            transition-delay: 3.5s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(16) {
            transition-delay: 3.75s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(17) {
            transition-delay: 4s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(18) {
            transition-delay: 4.25s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(19) {
            transition-delay: 4.5s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(20) {
            transition-delay: 4.75s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(21) {
            transition-delay: 5s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(22) {
            transition-delay: 5.25s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(23) {
            transition-delay: 5.5s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(24) {
            transition-delay: 5.75s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(25) {
            transition-delay: 6s; }
          #main-navigation.active #navigation-content .second-column .parent-menu li:nth-child(26) {
            transition-delay: 6.25s; } }
      #main-navigation.active #navigation-content .second-column .default-menu li,
      #main-navigation.active #navigation-content .second-column .children-menu li {
        opacity: 1;
        transform: translateX(0); }
      #main-navigation.active #navigation-content .copyright {
        transform: translateY(0);
        transition-delay: 1s; }
  #main-navigation.search-off #interactive-search {
    opacity: 0;
    transform: translateY(-2rem);
    pointer-events: none;
    transition: all .7s ease; }
  #main-navigation.search-off #interactive-container {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
    transition: all .7s ease; }
  #main-navigation.search-on #interactive-search {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
    transition: all .7s ease;
    z-index: 1; }
  #main-navigation.search-on #interactive-container {
    opacity: 0;
    transform: translateY(2rem);
    pointer-events: none;
    transition: all .7s ease; }

footer {
  position: relative;
  font-family: 'Exo',serif; }
  footer .disclaimer {
    color: #adb5bd;
    line-height: 1; }
    footer .disclaimer img {
      height: 120px; }
  footer .menus .nav-link {
    color: #000; }
    @media (max-width: 767.98px) {
      footer .menus .nav-link {
        padding: 0.5rem 0; } }
  footer .first-column .option-menu {
    background-color: #fff; }
    footer .first-column .option-menu .service-menu:after {
      content: '';
      display: block;
      width: 75%;
      border-bottom: 1px #dee2e6 solid; }
    footer .first-column .option-menu .social-icon a {
      width: 1.75rem;
      display: inline-block; }
      footer .first-column .option-menu .social-icon a img {
        width: 100%;
        opacity: .15;
        transition: .4s opacity ease; }
      footer .first-column .option-menu .social-icon a:hover img {
        opacity: .35; }
    footer .first-column .option-menu li {
      margin: 0 0 10px 0; }
    footer .first-column .option-menu a {
      display: inline-block;
      color: #adb5bd;
      font-size: .8rem;
      text-transform: uppercase; }
    @media (max-width: 991.98px) {
      footer .first-column .option-menu {
        position: sticky;
        top: 0; } }
  footer .menu-footer-1 {
    font-size: .9rem;
    letter-spacing: .1em;
    text-transform: uppercase; }
  footer .menu-footer-2 {
    font-size: .9rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .1em; }
    footer .menu-footer-2 li a {
      padding: .5rem 1rem; }
  footer .partner-menu img {
    max-height: 40px; }
  footer .partner-menu .developedby {
    opacity: .1;
    transition: opacity .25s ease; }
    footer .partner-menu .developedby img {
      height: 25px; }
    footer .partner-menu .developedby:hover {
      opacity: .25; }
  footer .copyright {
    color: #979797;
    transition: transform 1.6s ease;
    transition-delay: .4s; }
    footer .copyright p {
      display: inline-block;
      text-transform: uppercase;
      font-size: 0.75rem; }
    footer .copyright .services-link {
      display: inline-block; }
      footer .copyright .services-link a {
        text-transform: uppercase;
        font-size: 0.75rem;
        color: #979797; }
  footer #credits-toggler {
    display: inline-block;
    position: relative;
    cursor: pointer; }
    footer #credits-toggler #credits-tooltip {
      display: none;
      position: absolute;
      bottom: 100%;
      right: 0;
      background: rgba(0, 0, 0, 0.8);
      color: white;
      padding: .8em;
      font-size: 13px;
      text-transform: none;
      border-radius: 4px;
      width: 260px; }
    footer #credits-toggler:hover #credits-tooltip {
      display: block; }
  footer .vam-line {
    position: relative;
    height: 100px;
    width: 100%;
    left: 0;
    background-image: linear-gradient(90deg, #4DB9D5, #214F87);
    overflow: hidden; }
    @media (max-width: 1199.98px) {
      footer .vam-line {
        height: 60px; } }
    @media (max-width: 991.98px) {
      footer .vam-line {
        height: 50px; } }
    @media (max-width: 767.98px) {
      footer .vam-line {
        height: 35px; } }
    footer .vam-line:before {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: url("../img/vectors/bg-shaped.svg");
      background-size: 100vw;
      background-repeat: no-repeat;
      background-position: top;
      z-index: 1; }
  footer .landing .logo {
    height: 100px;
    filter: invert(1); }
  footer .landing .municipality-logo {
    height: 80px; }
  footer .landing .webagency {
    display: inline-block;
    height: 16px;
    opacity: .2;
    transition: opacity .4s ease; }
    footer .landing .webagency img {
      height: 100%;
      vertical-align: -.1em; }
    footer .landing .webagency:hover {
      opacity: 1; }

#booking > .row {
  width: 100%;
  max-width: 1660px; }

.container-map {
  margin-top: 8.75rem; }

.main-event {
  position: relative;
  z-index: 1;
  pointer-events: none; }
  .main-event .container-img-event {
    padding: 0; }
    .main-event .container-img-event img {
      width: 100%;
      height: 400px;
      max-width: 800px;
      max-height: 400px;
      object-fit: cover;
      object-position: center; }
      @media (max-width: 767.98px) {
        .main-event .container-img-event img {
          height: 250px; } }
  .main-event .event-box {
    max-width: 450px;
    background: #fff;
    position: relative;
    margin-top: -15rem;
    box-shadow: 0 0.3em 0.8em -0.25em rgba(0, 0, 0, 0.2);
    pointer-events: all; }
    @media (max-width: 991.98px) {
      .main-event .event-box {
        margin-top: -1.8rem;
        max-width: 90%;
        padding: 1.8rem;
        margin: -5.3rem auto 0 auto; } }
    .main-event .event-box .caption {
      font-family: 'Exo',serif;
      color: #6c757d; }
    .main-event .event-box .title {
      letter-spacing: .1em;
      max-width: 200px; }
    .main-event .event-box p {
      font-family: 'Exo',serif;
      font-size: 1rem;
      font-weight: 500; }
  .main-event .event-link {
    position: absolute;
    bottom: 0;
    right: 0; }

@media (min-width: 768px) {
  .more-events .official-portal .cover {
    transform: translate(3rem, -3rem); } }

.more-events .official-portal .text-container:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  transition: 1.5s all;
  height: 1px;
  background: #000; }
  @media (max-width: 991.98px) {
    .more-events .official-portal .text-container:after {
      display: none; } }

.more-events .official-portal span {
  font-size: 1rem;
  font-family: "Exo", sans-serif;
  letter-spacing: .1em;
  text-transform: uppercase; }

.more-events .official-portal .h2 {
  font-size: 2.25rem;
  letter-spacing: .1em; }

.more-events .official-portal p {
  font-family: "Exo", sans-serif; }

@media (min-width: 768px) {
  .more-events .official-portal .btn-more {
    margin-bottom: -1rem; } }

.more-events .official-portal .btn-more a {
  font-family: "Exo", sans-serif;
  color: #000;
  font-size: 0.8rem;
  letter-spacing: 1px;
  border: 1px solid;
  transition: .2s all;
  text-transform: uppercase; }
  .more-events .official-portal .btn-more a:hover {
    background-color: #e9ecef;
    text-decoration: none; }
  @media (max-width: 991.98px) {
    .more-events .official-portal .btn-more a {
      display: inline-block;
      margin-top: 0.6rem; } }

.more-events .general-events {
  position: relative;
  background-image: linear-gradient(-180deg, transparent 0%, transparent 20%, #EAF3F8 20%, #EAF3F8 20%);
  overflow: hidden; }
  @media (min-width: 576px) {
    .more-events .general-events {
      padding-bottom: 6.25rem; } }
  .more-events .general-events .tns-ovh {
    overflow: visible; }
    @media (max-width: 767.98px) {
      .more-events .general-events .tns-ovh {
        overflow: hidden; } }
  .more-events .general-events .tns-controls {
    display: flex; }
    .more-events .general-events .tns-controls [data-controls="prev"],
    .more-events .general-events .tns-controls [data-controls="next"] {
      zoom: .8; }
    .more-events .general-events .tns-controls [data-controls="prev"] {
      margin: 0 .5em .5em auto; }
  .more-events .general-events #carousel-events {
    margin: 0;
    position: relative;
    z-index: 1; }
    .more-events .general-events #carousel-events .item {
      cursor: pointer;
      transition: box-shadow .4s ease, opacity .4s ease; }
      .more-events .general-events #carousel-events .item a {
        color: inherit;
        text-decoration: none; }
      .more-events .general-events #carousel-events .item:nth-of-type(even) {
        margin-top: 3rem; }
        @media (max-width: 991.98px) {
          .more-events .general-events #carousel-events .item:nth-of-type(even) {
            margin-top: inherit; } }
      .more-events .general-events #carousel-events .item .featured-image {
        display: block;
        position: relative;
        height: 14.3rem;
        width: 100%;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        transition: background-position .4s ease; }
        .more-events .general-events #carousel-events .item .featured-image span {
          position: absolute;
          right: .8rem;
          bottom: .8rem;
          color: #fff;
          font-size: 2rem;
          font-weight: 300;
          line-height: .8;
          z-index: 1;
          transition: transform .4s ease; }
          .more-events .general-events #carousel-events .item .featured-image span:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.8rem;
            height: 1.8rem;
            transform: translate(-50%, -52%);
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            opacity: 0;
            z-index: 1;
            transition: opacity .4s ease; }
        .more-events .general-events #carousel-events .item .featured-image:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          background-color: rgba(0, 0, 0, 0.1);
          z-index: 0;
          transition: height .4s ease; }
      .more-events .general-events #carousel-events .item .content {
        background-color: #fff;
        padding: 2.25rem 1.75rem;
        pointer-events: none; }
        .more-events .general-events #carousel-events .item .content .date {
          display: inline-block;
          padding: .25em .5em;
          color: #6c757d;
          font-size: .8em;
          text-transform: capitalize;
          background-color: #e9ecef;
          border-radius: .3em; }
        .more-events .general-events #carousel-events .item .content h4 {
          font-size: 1.2rem;
          letter-spacing: .1em; }
          @media (min-width: 992px) {
            .more-events .general-events #carousel-events .item .content h4 {
              max-width: 90%; } }
        .more-events .general-events #carousel-events .item .content p {
          font-family: 'Exo',serif;
          font-size: 0.9rem;
          font-weight: 300;
          text-align: left !important; }
      .more-events .general-events #carousel-events .item:hover .featured-image {
        background-position: 50% 100%; }
        .more-events .general-events #carousel-events .item:hover .featured-image span {
          transform: rotate(90deg); }
          .more-events .general-events #carousel-events .item:hover .featured-image span:after {
            opacity: 1; }
        .more-events .general-events #carousel-events .item:hover .featured-image:after {
          height: 100%; }

#newFullSlider {
  height: 100vh;
  color: #fff;
  overflow: hidden; }
  #newFullSlider .text-container {
    max-width: 100%;
    margin-bottom: calc((100vh / 3)/2); }
    @media (max-width: 767.98px) {
      #newFullSlider .text-container {
        margin-bottom: calc((100vh / 3)/2.5); } }
    @media (min-width: 768px) {
      #newFullSlider .text-container {
        margin-bottom: calc((100vh / 4)/2); } }
  #newFullSlider #slidesCaptions {
    overflow: hidden; }
    #newFullSlider #slidesCaptions .slick-list {
      overflow: visible; }
  #newFullSlider .slidesCaptions {
    font-family: 'Trajan', serif;
    font-size: 1rem;
    letter-spacing: .1em; }
    #newFullSlider .slidesCaptions h5 {
      font-size: 1em;
      font-weight: 600;
      letter-spacing: .3em;
      text-transform: uppercase; }
    #newFullSlider .slidesCaptions .btn-pagelink {
      position: relative; }
      #newFullSlider .slidesCaptions .btn-pagelink:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: -4rem;
        height: 1px;
        width: 50%;
        background-color: #fff;
        transform: translateY(-50%);
        z-index: -1; }
  #newFullSlider .slidesFullscreen {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: -1; }
    #newFullSlider .slidesFullscreen img,
    #newFullSlider .slidesFullscreen video {
      height: 100vh;
      width: 100vw;
      object-fit: cover;
      object-position: center; }
    #newFullSlider .slidesFullscreen:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 1;
      transition: background-color 1s ease; }
    #newFullSlider .slidesFullscreen.video-playing:before {
      background-color: rgba(0, 0, 0, 0.1); }
  #newFullSlider .thumbnails-tool .thumb-wrapper {
    position: relative; }
    #newFullSlider .thumbnails-tool .thumb-wrapper:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: calc(100% - 3rem);
      background-color: #fff; }
  #newFullSlider .thumbnails-tool label {
    font-size: .75rem;
    letter-spacing: .3em;
    text-transform: uppercase; }
  #newFullSlider .thumbnails-tool .slick-slide {
    margin-right: 1rem;
    max-height: 150px;
    border: 2px transparent solid;
    outline: none;
    overflow: hidden;
    transition: border 1.2s ease; }
    #newFullSlider .thumbnails-tool .slick-slide.slick-current {
      border: 2px #fff solid; }
      #newFullSlider .thumbnails-tool .slick-slide.slick-current [data-type="video"] {
        position: relative; }
        #newFullSlider .thumbnails-tool .slick-slide.slick-current [data-type="video"]:before {
          opacity: 0; }
  #newFullSlider .thumbnails-tool .item[data-type="video"] {
    position: relative; }
    #newFullSlider .thumbnails-tool .item[data-type="video"]:before {
      content: '';
      display: block;
      position: absolute;
      bottom: .5rem;
      left: .5rem;
      font-family: 'IconFont';
      font-size: 1.2rem;
      line-height: 1.25;
      opacity: .5;
      transition: opacity 1s ease; }
  #newFullSlider .thumbnails-tool img,
  #newFullSlider .thumbnails-tool video {
    height: 150px;
    width: 260px;
    object-fit: cover;
    object-position: center; }
  #newFullSlider .sliderDots button {
    padding: 0;
    height: .5rem;
    width: .5rem;
    color: transparent;
    font-size: 0;
    line-height: 1;
    border: 1px #fff solid;
    border-radius: 50%;
    transition: background-color .7s ease; }
  #newFullSlider .sliderDots li button {
    background-color: rgba(255, 255, 255, 0);
    outline: none; }
  #newFullSlider .sliderDots li.slick-active button, #newFullSlider .sliderDots li:hover button, #newFullSlider .sliderDots li:active button {
    background-color: white; }
  #newFullSlider #controlArrows button {
    padding: 0;
    margin: .5rem 0;
    display: block;
    height: 3rem;
    width: 3rem;
    color: transparent;
    font-size: 0;
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 50%;
    outline: none;
    transition: background-color .4s ease; }
    #newFullSlider #controlArrows button.slick-prev {
      background-image: url("../img/vectors/up-arrow.svg"); }
    #newFullSlider #controlArrows button.slick-next {
      background-image: url("../img/vectors/down-arrow.svg"); }
    #newFullSlider #controlArrows button:hover {
      background-color: rgba(255, 255, 255, 0.4); }

.featured-contents {
  position: relative;
  z-index: 1;
  pointer-events: none; }
  .featured-contents .container-img-event {
    padding: 0; }
    .featured-contents .container-img-event img {
      width: 100%;
      height: 320px;
      max-width: 800px;
      max-height: 320px;
      object-fit: cover;
      object-position: center; }
      @media (max-width: 767.98px) {
        .featured-contents .container-img-event img {
          height: 250px; } }
  .featured-contents .event-box {
    max-width: 450px;
    background: #fff;
    position: relative;
    margin-top: -3rem;
    box-shadow: 0 0.3em 0.8em -0.25em rgba(0, 0, 0, 0.2);
    pointer-events: all; }
    @media (max-width: 991.98px) {
      .featured-contents .event-box {
        margin-top: -1.8rem;
        max-width: 90%;
        padding: 1.8rem;
        margin: -5.3rem auto 0 auto; } }
    .featured-contents .event-box .caption {
      font-family: 'Exo',serif;
      color: #6c757d; }
    .featured-contents .event-box .title {
      letter-spacing: .1em;
      max-width: 200px; }
    .featured-contents .event-box p {
      font-family: 'Exo',serif;
      font-size: 1rem;
      font-weight: 500; }

#gallery-carousel {
  overflow: hidden; }
  #gallery-carousel .tns-ovh {
    overflow: visible; }
  #gallery-carousel .gallery-autoscroll {
    display: inline-flex;
    position: relative; }
    #gallery-carousel .gallery-autoscroll img {
      display: inline-block;
      height: 200px;
      width: 200px;
      object-fit: cover;
      border: 0.25em solid #fff; }
      @media (max-width: 767.98px) {
        #gallery-carousel .gallery-autoscroll img {
          height: 120px;
          width: 120px; } }

#media-slider {
  position: relative;
  color: #fff;
  overflow: hidden; }
  #media-slider img,
  #media-slider video {
    height: 100vh;
    width: 100vw;
    max-height: 700px;
    object-fit: cover;
    object-position: center;
    background-color: #e9ecef;
    border: 0.25em #fff solid; }
    @media (max-width: 767.98px) {
      #media-slider img,
      #media-slider video {
        height: 350px; } }
  #media-slider .nav-container {
    display: flex;
    position: absolute;
    top: 50%;
    width: 100%;
    justify-content: space-between;
    transform: translateY(-50%);
    pointer-events: none; }
    #media-slider .nav-container button {
      pointer-events: all; }
      #media-slider .nav-container button.prev {
        float: left; }
        #media-slider .nav-container button.prev:before {
          transform: rotate(180deg); }
      #media-slider .nav-container button.next {
        float: right;
        margin-left: auto; }

.media-gallery {
  position: relative;
  color: #fff;
  background-image: url("../img/water-background.jpg");
  background-size: 130% 130%;
  background-repeat: no-repeat;
  background-position: 50% 0%; }
  .media-gallery:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1; }
  .media-gallery .container-text-gallery {
    position: relative;
    z-index: 1; }
    .media-gallery .container-text-gallery h4 {
      letter-spacing: 2px; }
    .media-gallery .container-text-gallery h3 {
      font-size: 2rem;
      letter-spacing: 2px; }
    .media-gallery .container-text-gallery p {
      margin: 0 auto;
      font-family: 'Exo',serif;
      letter-spacing: .1em; }

#partners .partners {
  opacity: .8; }
  @media (max-width: 767.98px) {
    #partners .partners {
      zoom: .5; } }
  @media (min-width: 576px) and (max-width: 1199.98px) {
    #partners .partners {
      zoom: .7; } }
  #partners .partners .logo {
    display: inline-block; }
    @media (max-width: 767.98px) {
      #partners .partners .logo {
        margin: .5rem;
        width: 25%;
        max-width: 280px; } }
    #partners .partners .logo img {
      height: 80px;
      max-height: 90px;
      width: auto;
      object-fit: contain;
      filter: saturate(0);
      transition: filter .4s ease; }
      #partners .partners .logo img:hover {
        /*filter: saturate(1);*/ }
      @media (max-width: 767.98px) {
        #partners .partners .logo img {
          height: inherit;
          max-height: inherit; } }
      #partners .partners .logo img.stradadelvino {
        height: 60px; }
        @media (max-width: 767.98px) {
          #partners .partners .logo img.stradadelvino {
            height: inherit; } }

#macroarea-description .frase-intro p {
  font-size: 1.8rem;
  letter-spacing: .1em;
  width: 90%;
  color: #6c757d; }
  @media (max-width: 991.98px) {
    #macroarea-description .frase-intro p {
      width: 100%; } }

#macroarea-description .frase-intro img {
  max-width: 50px; }
  @media (max-width: 991.98px) {
    #macroarea-description .frase-intro img {
      display: none; } }

@media (max-width: 767.98px) {
  #macroarea-description .frase-intro {
    margin-top: 2rem; } }

@media (min-width: 768px) {
  #macroarea-description .macro-description {
    max-width: 400px; } }

.single-video {
  position: relative;
  z-index: 1; }

.contact-form {
  margin: 3.1rem 0; }
  .contact-form .row {
    margin: 1.25rem 0; }
    .contact-form .row input, .contact-form .row select {
      border: 1px solid #ddd;
      height: 3.75rem;
      color: #888; }
    .contact-form .row textarea {
      color: #888;
      border: 1px solid #ddd;
      max-height: 18.75rem;
      min-height: 9.3rem; }

.btn-contacts {
  color: #fff;
  opacity: 0.8;
  background-color: #056DA9;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.6rem 1.25rem;
  margin-right: 0.6rem;
  border-radius: 0;
  transition: .4s all; }
  .btn-contacts:hover {
    opacity: 1;
    color: #fff; }

.info-box {
  margin: 5rem 0; }
  .info-box .element-box {
    text-align: left;
    width: 100%; }
    .info-box .element-box div {
      width: 100%; }
      .info-box .element-box div .title {
        margin-bottom: 1.25rem;
        color: #214f87;
        font-weight: 500;
        border-bottom: 2px solid #214f87; }

#gallery-video img {
  width: 100%;
  object-fit: cover;
  height: 300px; }

#gallery-video #video-gallery li {
  cursor: pointer;
  opacity: .9;
  border: 0.25rem #fff solid;
  transition: .5s all; }
  #gallery-video #video-gallery li:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2.5rem;
    height: 2.5rem;
    background-image: url("../img/vectors/play-icon.svg");
    background-size: contain;
    transform: translate(-50%, -50%);
    transition: opacity .5s ease; }
  #gallery-video #video-gallery li:hover {
    opacity: 1; }
    #gallery-video #video-gallery li:hover:after {
      opacity: 0; }

#gallery-foto .fotogallery li img {
  width: 100%;
  object-fit: cover;
  height: 300px; }

.lg-sub-html p {
  font-size: 1rem; }

#hero {
  position: relative;
  min-height: 550px;
  height: 100vh;
  max-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    #hero {
      min-height: 430px; } }
  #hero > .row {
    width: 100%;
    max-width: 1660px;
    z-index: 1; }
  #hero:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1)); }
  #hero .content {
    color: #fff;
    font-size: 1.15rem;
    z-index: 1; }
    #hero .content:after {
      content: '';
      position: absolute;
      top: calc(100% + 2rem);
      left: calc(100% + 4rem);
      height: 1px;
      width: 0;
      background-color: #fff;
      transition: 1.5s all; }
    #hero .content h1,
    #hero .content .title {
      font-size: 3.5rem;
      font-size: 4.5vw;
      text-transform: uppercase;
      letter-spacing: .1em; }
      @media (max-width: 767.98px) {
        #hero .content h1,
        #hero .content .title {
          font-size: 2.3rem; } }
    @media (max-width: 767.98px) {
      #hero .content {
        font-size: 1rem; } }
    #hero .content .lettering {
      width: 100%; }

#service-hero {
  height: 250px;
  min-height: 200px;
  color: #000;
  background-color: #056DA9; }
  #service-hero .row {
    background-color: #fff; }
  #service-hero .title {
    color: #000;
    font-size: 2.5rem; }

.text_1 .caption {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Trajan", serif;
  font-size: 1rem;
  letter-spacing: 0.75rem; }

.text_1 .title {
  font-family: "Exo", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.25rem; }

.text_1 .content .caption_left,
.text_1 .content .caption_right {
  color: #6c757d;
  font-family: "Trajan";
  font-size: .8rem; }
  .text_1 .content .caption_left:before,
  .text_1 .content .caption_right:before {
    content: '';
    display: block;
    margin-top: .5em;
    margin-bottom: 1em;
    height: 1px;
    width: 100%;
    background-color: #ced4da; }

.text_2 .content .description:before {
  content: '';
  display: block;
  margin-top: .5em;
  margin-bottom: 2rem;
  height: 1px;
  width: 30%;
  min-width: 100px;
  background-color: #ced4da; }

.text_2 img {
  width: 100%; }

.text_3 .title {
  font-family: "Exo", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.25rem; }

.text_3 .caption {
  color: #6c757d;
  font-family: "Trajan";
  font-size: .8rem; }
  .text_3 .caption:before {
    content: '';
    display: block;
    margin-top: .5em;
    margin-bottom: 1em;
    height: 1px;
    width: 100%;
    background-color: #ced4da; }

.features {
  color: #056DA9;
  background-color: rgba(5, 109, 169, 0.1); }

.mansory {
  overflow: hidden; }
  .mansory .gallery {
    display: grid;
    grid-template-columns: 25% 50% 25%; }
    @media (max-width: 991.98px) {
      .mansory .gallery {
        display: inline-block; } }
    .mansory .gallery img {
      height: 415px;
      width: 100%;
      object-fit: cover;
      object-position: center;
      border: 0.5rem solid #fff; }
      @media (max-width: 991.98px) {
        .mansory .gallery img {
          height: 350px; } }
      @media (min-width: 768px) {
        .mansory .gallery img:nth-child(2), .mansory .gallery img:nth-child(5) {
          height: 390px;
          border: 0.5rem solid #fff; }
        .mansory .gallery img:nth-child(5) {
          margin-top: -25px; } }

.services .row {
  position: relative;
  background-size: 50% auto;
  background-repeat: no-repeat;
  overflow: hidden;
  transition: transform .4s ease, margin .25s ease;
  cursor: pointer; }
  @media (max-width: 1199.98px) {
    .services .row {
      background-size: cover; } }
  .services .row span {
    position: absolute;
    top: 1rem;
    color: #fff;
    font-size: 2rem;
    font-weight: 300;
    line-height: .8;
    z-index: 2;
    transform: translateY(calc(-100% - 1rem));
    transition: transform .4s ease; }
    .services .row span:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.8rem;
      height: 1.8rem;
      transform: translate(-50%, -52%);
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 50%;
      opacity: 0;
      z-index: 1;
      transition: opacity .4s ease; }
  .services .row .content {
    min-height: 265px;
    background-color: #fff;
    transition: background-color .4s ease;
    z-index: 2; }
    @media (min-width: 992px) {
      .services .row .content {
        background-color: #fff; } }
    @media (max-width: 991.98px) {
      .services .row .content {
        padding: 1.5rem;
        color: #fff; } }
    @media (max-width: 767.98px) {
      .services .row .content {
        background-color: transparent; } }
    .services .row .content h3 {
      letter-spacing: .1em; }
      @media (max-width: 991.98px) {
        .services .row .content h3 {
          font-size: 1.4rem; } }
    .services .row .content p a {
      display: none; }
    @media (max-width: 991.98px) {
      .services .row .content p {
        font-size: 1rem; } }
  .services .row:after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 0;
    background-color: rgba(0, 0, 0, 0.1);
    transition: width .7s ease;
    z-index: 1; }
  .services .row:hover {
    z-index: 2; }
    @media (max-width: 767.98px) {
      .services .row:hover {
        margin: 0; } }
    .services .row:hover span {
      transform: translateY(0) rotate(90deg); }
      .services .row:hover span:after {
        opacity: 1; }
    .services .row:hover .content {
      background-color: #f8f9fa; }
      @media (max-width: 767.98px) {
        .services .row:hover .content {
          background-color: transparent; } }
    .services .row:hover:after {
      width: 100%; }
  .services .row:nth-child(odd) {
    background-position: left; }
    @media (max-width: 1199.98px) {
      .services .row:nth-child(odd) {
        background-position: center; } }
    .services .row:nth-child(odd) span {
      left: 1rem; }
    @media (min-width: 992px) {
      .services .row:nth-child(odd) .content {
        margin-left: 50%; } }
    @media (max-width: 991.98px) {
      .services .row:nth-child(odd) .content {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)); } }
    .services .row:nth-child(odd):after {
      left: 0; }
  .services .row:nth-child(even) {
    background-position: right; }
    @media (max-width: 1199.98px) {
      .services .row:nth-child(even) {
        background-position: center; } }
    .services .row:nth-child(even) span {
      right: 1rem; }
    @media (min-width: 992px) {
      .services .row:nth-child(even) .content {
        margin-right: 50%; } }
    @media (max-width: 991.98px) {
      .services .row:nth-child(even) .content {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)); } }
    .services .row:nth-child(even):after {
      right: 0; }
  .services .row:last-child {
    margin: 3.5rem 0;
    margin-top: 0px;
    background-color: #f8f9fa;
    background-position: left; }
    @media (max-width: 1199.98px) {
      .services .row:last-child {
        background-position: center; } }
    @media (max-width: 991.98px) {
      .services .row:last-child {
        padding-top: 180px;
        margin: 0;
        background-size: 100%;
        background-position: top center;
        border: 1rem #fff solid;
        color: #000; } }
    @media (max-width: 767.98px) {
      .services .row:last-child {
        background-size: cover; }
        .services .row:last-child:after {
          display: none; } }
    .services .row:last-child span {
      left: 1rem;
      right: auto; }
    .services .row:last-child .content {
      background-color: #f8f9fa;
      z-index: 2; }
      @media (min-width: 992px) {
        .services .row:last-child .content {
          margin-left: 50%; } }
      @media (max-width: 991.98px) {
        .services .row:last-child .content {
          color: #000;
          background-image: none;
          justify-content: center !important;
          min-height: 200px; } }
      @media (max-width: 767.98px) {
        .services .row:last-child .content {
          background-color: #f8f9fa; } }
    .services .row:last-child:after {
      right: 0; }

.main-service {
  cursor: pointer; }
  .main-service .row {
    position: relative;
    min-height: 400px;
    color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    transition: background-size .7s ease; }
    @media (max-width: 767.98px) {
      .main-service .row {
        border: 1rem #fff solid; } }
    .main-service .row:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1));
      transition: width .7s ease; }
      @media (max-width: 767.98px) {
        .main-service .row:after {
          background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1)); } }
    .main-service .row span {
      position: absolute;
      left: 1rem;
      top: 1rem;
      color: #fff;
      font-size: 2rem;
      font-weight: 300;
      line-height: .8;
      z-index: 1;
      transform: translateY(calc(-100% - 1rem));
      transition: transform .4s ease; }
      .main-service .row span:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.8rem;
        height: 1.8rem;
        transform: translate(-50%, -52%);
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 50%;
        opacity: 0;
        z-index: 1;
        transition: opacity .4s ease; }
    .main-service .row .content {
      z-index: 1; }
      .main-service .row .content a {
        display: none; }
    .main-service .row:hover:after {
      width: 200%; }
    .main-service .row:hover span {
      transform: translateY(0) rotate(90deg); }
      .main-service .row:hover span:after {
        opacity: 1; }

.webcam-service {
  cursor: pointer; }
  .webcam-service .row {
    position: relative;
    height: 400px;
    min-height: 400px;
    color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    transition: background-size .7s ease; }
    @media (max-width: 767.98px) {
      .webcam-service .row {
        border: 1rem #fff solid; } }
    .webcam-service .row:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1));
      transition: width .7s ease; }
      @media (max-width: 767.98px) {
        .webcam-service .row:after {
          background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1)); } }
    .webcam-service .row a {
      width: 100%;
      height: 100%;
      color: #fff;
      text-decoration: none;
      z-index: 1; }
    .webcam-service .row span {
      position: absolute;
      left: 1rem;
      top: 1rem;
      color: #fff;
      font-size: 2rem;
      font-weight: 300;
      line-height: .8;
      z-index: 1;
      transform: translateY(calc(-100% - 1rem));
      transition: transform .4s ease; }
      .webcam-service .row span:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.8rem;
        height: 1.8rem;
        transform: translate(-50%, -52%);
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 50%;
        opacity: 0;
        z-index: 1;
        transition: opacity .4s ease; }
    .webcam-service .row .content {
      z-index: 1; }
      .webcam-service .row .content a {
        display: none; }
    .webcam-service .row:hover:after {
      width: 200%; }
    .webcam-service .row:hover span {
      transform: translateY(0) rotate(90deg); }
      .webcam-service .row:hover span:after {
        opacity: 1; }

.point-of-interest .poi {
  position: relative;
  color: #fff;
  width: 100%;
  min-height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0.25rem #fff solid;
  overflow: hidden;
  cursor: pointer; }
  @media (max-width: 767.98px) {
    .point-of-interest .poi {
      min-height: 300px; } }
  .point-of-interest .poi span {
    position: absolute;
    top: 1rem;
    color: #fff;
    font-size: 2rem;
    font-weight: 300;
    line-height: .8;
    z-index: 2;
    transform: translateY(calc(-100% - 1rem));
    transition: transform .4s ease; }
    .point-of-interest .poi span:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.8rem;
      height: 1.8rem;
      transform: translate(-50%, -52%);
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 50%;
      opacity: 0;
      z-index: 1;
      transition: opacity .4s ease; }
  .point-of-interest .poi:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.25));
    z-index: 0; }
  .point-of-interest .poi h3 {
    transition: transform .4s ease;
    z-index: 1; }
  .point-of-interest .poi p {
    transition: transform .4s ease;
    z-index: 1; }
    .point-of-interest .poi p a {
      display: none; }
  .point-of-interest .poi a {
    max-width: 80%; }
  .point-of-interest .poi:hover span {
    transform: translateY(0) rotate(90deg); }
    .point-of-interest .poi:hover span:after {
      opacity: 1; }
  .point-of-interest .poi:hover h3,
  .point-of-interest .poi:hover p {
    transform: translateY(-0.5rem); }

#contact-form .form-box {
  background-color: #fff; }
  @media (max-width: 767.98px) {
    #contact-form .form-box {
      border-right: transparent 1rem solid;
      border-left: transparent 1rem solid; } }
  #contact-form .form-box form {
    position: relative; }
    #contact-form .form-box form:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      height: 1px;
      width: calc(100% - 3rem);
      background-color: #e9ecef;
      transform: translateX(-50%); }
      @media (max-width: 767.98px) {
        #contact-form .form-box form:before {
          width: calc(100% - 1rem); } }
    #contact-form .form-box form label {
      width: 100%; }
  #contact-form .form-box .gdpr-box {
    color: #056DA9;
    background-color: rgba(5, 109, 169, 0.1); }
    #contact-form .form-box .gdpr-box label {
      font-size: .9rem;
      letter-spacing: normal;
      text-transform: none; }

#edited_by .row > div {
  color: #056DA9;
  font-size: .8rem;
  background-color: rgba(5, 109, 169, 0.1); }
  #edited_by .row > div a {
    color: #056DA9;
    font-weight: 500;
    text-decoration: none; }

section.quotation .container {
  background-color: #e6f0f6; }
  section.quotation .container .title {
    font-family: "Exo";
    color: #056da9; }

#landing-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 600px;
  height: 100vh;
  max-height: 100vh;
  position: relative; }
  #landing-hero:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)); }
  #landing-hero .hero-description {
    color: #fff;
    z-index: 1; }

.article-top-header {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 6rem;
  min-height: 300px;
  height: 100vh;
  max-height: 100vh;
  position: relative;
  pointer-events: none; }
  .article-top-header:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)); }
  @media (max-width: 767.98px) {
    .article-top-header {
      background-image: url(/wp-content/themes/vam/assets/img/mobile-terme-article-image.jpg); } }
  @media (max-width: 991.98px) {
    .article-top-header {
      padding: .6rem 0;
      min-height: 70vh; } }
  @media (min-width: 1200px) {
    .article-top-header {
      min-height: 50vh; } }
  .article-top-header .container-fluid {
    z-index: 10; }

#article-content .description-article {
  font-size: 2.1rem;
  line-height: 1.2;
  font-family: "Trajan";
  letter-spacing: 0.32rem;
  color: #A0A0A0; }
  #article-content .description-article a {
    display: none;
    pointer-events: none; }
  @media (max-width: 991.98px) {
    #article-content .description-article {
      font-size: 1.3rem;
      margin: 0px auto 30px auto; } }

#article-content .content-media div img {
  width: 100%; }

@media (max-width: 991.98px) {
  #article-content .content-media div {
    padding: 0 15px; } }

#article-content .content-media a {
  display: block;
  color: #4F4F4F;
  text-align: center;
  text-transform: uppercase; }
  @media (max-width: 991.98px) {
    #article-content .content-media a {
      display: none; } }
  #article-content .content-media a.next:after {
    transform: scale(-1); }
  #article-content .content-media a:after {
    content: '';
    background-image: url(/wp-content/themes/vam/assets/img/vectors/arrow-article.svg);
    width: 15px;
    margin: 15px auto 0 auto;
    height: 10px;
    display: block;
    background-size: cover; }

#article-content .content-text p {
  line-height: 1.5;
  letter-spacing: .05rem; }

#article-content .gallery-container {
  overflow: hidden; }
  #article-content .gallery-container .gallery {
    display: grid;
    grid-template-columns: 25% 50% 25%; }
    @media (max-width: 991.98px) {
      #article-content .gallery-container .gallery {
        display: inline-block; } }
    #article-content .gallery-container .gallery img {
      height: 415px;
      width: 100%;
      object-fit: cover;
      object-position: center;
      border: 0.5rem solid #fff; }
      @media (max-width: 991.98px) {
        #article-content .gallery-container .gallery img {
          height: 350px; } }
      @media (min-width: 768px) {
        #article-content .gallery-container .gallery img:nth-child(2), #article-content .gallery-container .gallery img:nth-child(5) {
          height: 390px;
          border: 0.5rem solid #fff; }
        #article-content .gallery-container .gallery img:nth-child(5) {
          margin-top: -25px; } }

#article-content .official-portal {
  text-align: right; }
  @media (max-width: 991.98px) {
    #article-content .official-portal {
      text-align: left; } }
  @media (max-width: 991.98px) {
    #article-content .official-portal .h2 {
      font-size: 1.4rem; } }
  #article-content .official-portal .text-container {
    padding: 0; }
    @media (max-width: 991.98px) {
      #article-content .official-portal .text-container {
        padding: 0 0.9rem; } }
    #article-content .official-portal .text-container:after {
      content: '';
      width: 0;
      transform: 1.5s all;
      height: 1px;
      background: #000;
      position: absolute;
      right: 0;
      left: auto; }
      @media (max-width: 991.98px) {
        #article-content .official-portal .text-container:after {
          display: block;
          position: static;
          margin: 0.9rem 0;
          width: 70%; } }
  #article-content .official-portal a {
    margin-top: 0;
    display: inline-block; }

#article-content .tns-ovh {
  display: initial; }

.btn-default {
  display: inline-block;
  position: relative;
  padding: .8rem 1.2rem;
  min-width: 200px;
  color: #fff;
  font-family: "Exo", sans-serif;
  font-size: .8rem;
  letter-spacing: .1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px #fff solid;
  border-radius: 0;
  transition: color .4s ease, background-color .4s ease, border .4s ease;
  overflow: hidden;
  z-index: 1; }
  .btn-default:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-height: 0;
    background-color: #fff;
    z-index: -1;
    transition: max-height .4s ease; }
  .btn-default:hover {
    color: #000;
    text-decoration: none;
    border: 1px #fff solid; }
    .btn-default:hover:after {
      max-height: 100%; }

.btn-black {
  display: inline-block;
  position: relative;
  padding: .8rem 1.2rem;
  min-width: 200px;
  color: #fff;
  font-family: "Exo", sans-serif;
  font-size: .8rem;
  letter-spacing: .1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px #fff solid;
  border-radius: 0;
  transition: color .4s ease, background-color .4s ease, border .4s ease;
  overflow: hidden;
  color: #000;
  border: 1px #000 solid; }
  .btn-black:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-height: 0;
    background-color: #fff;
    z-index: -1;
    transition: max-height .4s ease; }
  .btn-black:hover {
    color: #000;
    text-decoration: none;
    border: 1px #fff solid; }
    .btn-black:hover:after {
      max-height: 100%; }
  .btn-black:after {
    background-color: #056DA9; }
  .btn-black:hover {
    color: #fff;
    border: 1px #056DA9 solid; }

.btn-blue {
  display: inline-block;
  position: relative;
  padding: .8rem 1.2rem;
  min-width: 200px;
  color: #fff;
  font-family: "Exo", sans-serif;
  font-size: .8rem;
  letter-spacing: .1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px #fff solid;
  border-radius: 0;
  transition: color .4s ease, background-color .4s ease, border .4s ease;
  overflow: hidden;
  color: #fff;
  background-color: #056DA9;
  border: 1px #056DA9 solid; }
  .btn-blue:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-height: 0;
    background-color: #fff;
    z-index: -1;
    transition: max-height .4s ease; }
  .btn-blue:hover {
    color: #000;
    text-decoration: none;
    border: 1px #fff solid; }
    .btn-blue:hover:after {
      max-height: 100%; }
  .btn-blue:hover {
    color: #fff;
    background-color: #067dc2;
    border: 1px #056DA9 solid; }

.btn-booking {
  padding: .8rem 2rem;
  min-width: inherit;
  font-size: 0.7rem; }
  .btn-booking:after {
    display: none; }
  @media (max-width: 991.98px) {
    .btn-booking {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 15px 0;
      font-size: 0.85rem;
      letter-spacing: .2em;
      transform: translateY(0);
      transition: transform .4s ease; }
      .btn-booking.mobile-disable {
        transform: translateY(100%); } }

.btn-search {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dee2e6;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  background-color: #f8f9fa;
  border: none;
  border-radius: 50%;
  outline: none;
  transition: color .4s ease, background-color .4s ease; }
  .btn-search:before {
    content: '';
    display: block;
    position: absolute;
    color: inherit;
    font-family: 'IconFont';
    font-size: .8rem;
    vertical-align: middle;
    transition: background-position .4s ease; }
  .btn-search:hover {
    color: #343a40; }
  .btn-search:active {
    color: #fff;
    background-color: #056DA9; }
  .btn-search.active {
    color: rgba(5, 109, 169, 0.5);
    background-color: rgba(5, 109, 169, 0.2); }
    .btn-search.active:hover {
      color: #056DA9; }

.btn-arrow, .more-events .general-events .tns-controls [data-controls="prev"],
.more-events .general-events .tns-controls [data-controls="next"], #media-slider .nav-container button, .lg-outer .lg-actions .lg-next, .lg-outer .lg-actions .lg-prev {
  display: block;
  color: #fff;
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  background-color: #056DA9;
  border: none;
  border-radius: 0;
  transition: background-color .4s ease; }
  .btn-arrow:before, .more-events .general-events .tns-controls [data-controls="prev"]:before,
  .more-events .general-events .tns-controls [data-controls="next"]:before, #media-slider .nav-container button:before, .lg-outer .lg-actions .lg-next:before, .lg-outer .lg-actions .lg-prev:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    background-image: url("../img/vectors/arrow-right.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 1.025rem;
    transition: background-position .4s ease; }
  .btn-arrow:hover, .more-events .general-events .tns-controls :hover[data-controls="prev"],
  .more-events .general-events .tns-controls :hover[data-controls="next"], #media-slider .nav-container button:hover, .lg-outer .lg-actions .lg-next:hover, .lg-outer .lg-actions .lg-prev:hover, .btn-arrow:active, .more-events .general-events .tns-controls :active[data-controls="prev"],
  .more-events .general-events .tns-controls :active[data-controls="next"], #media-slider .nav-container button:active, .lg-outer .lg-actions .lg-next:active, .lg-outer .lg-actions .lg-prev:active {
    color: #fff;
    background-color: #067dc2; }
    .btn-arrow:hover:before, .more-events .general-events .tns-controls [data-controls="prev"]:hover:before,
    .more-events .general-events .tns-controls [data-controls="next"]:hover:before, #media-slider .nav-container button:hover:before, .lg-outer .lg-actions .lg-next:hover:before, .lg-outer .lg-actions .lg-prev:hover:before, .btn-arrow:active:before, .more-events .general-events .tns-controls [data-controls="prev"]:active:before,
    .more-events .general-events .tns-controls [data-controls="next"]:active:before, #media-slider .nav-container button:active:before, .lg-outer .lg-actions .lg-next:active:before, .lg-outer .lg-actions .lg-prev:active:before {
      background-position: 55%; }
  .btn-arrow.up:before, .more-events .general-events .tns-controls .up[data-controls="prev"]:before,
  .more-events .general-events .tns-controls .up[data-controls="next"]:before, #media-slider .nav-container button.up:before, .lg-outer .lg-actions .up.lg-next:before, .lg-outer .lg-actions .up.lg-prev:before {
    transform: rotate(270deg); }
  .btn-arrow.right:before, .more-events .general-events .tns-controls .right[data-controls="prev"]:before,
  .more-events .general-events .tns-controls .right[data-controls="next"]:before, #media-slider .nav-container button.right:before, .lg-outer .lg-actions .right.lg-next:before, .lg-outer .lg-actions .right.lg-prev:before {
    transform: rotate(0); }
  .btn-arrow.down:before, .more-events .general-events .tns-controls .down[data-controls="prev"]:before,
  .more-events .general-events .tns-controls .down[data-controls="next"]:before, #media-slider .nav-container button.down:before, .lg-outer .lg-actions .down.lg-next:before, .lg-outer .lg-actions .down.lg-prev:before {
    transform: rotate(90deg); }
  .btn-arrow.left:before, .more-events .general-events .tns-controls [data-controls="prev"]:before,
  .more-events .general-events .tns-controls .left[data-controls="next"]:before, #media-slider .nav-container button.left:before, #media-slider .nav-container .more-events .general-events .tns-controls button[data-controls="prev"]:before, .more-events .general-events .tns-controls #media-slider .nav-container button[data-controls="prev"]:before, .lg-outer .lg-actions .left.lg-next:before, .lg-outer .lg-actions .left.lg-prev:before {
    transform: rotate(180deg); }

.btn-load {
  display: inline-block;
  position: relative;
  padding: .8rem 1.2rem;
  min-width: 200px;
  color: #fff;
  font-family: "Exo", sans-serif;
  font-size: .8rem;
  letter-spacing: .1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px #fff solid;
  border-radius: 0;
  transition: color .4s ease, background-color .4s ease, border .4s ease;
  overflow: hidden;
  display: block;
  color: #fff;
  background-color: #056DA9;
  transition: background-color .4s ease; }
  .btn-load:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-height: 0;
    background-color: #fff;
    z-index: -1;
    transition: max-height .4s ease; }
  .btn-load:hover {
    color: #000;
    text-decoration: none;
    border: 1px #fff solid; }
    .btn-load:hover:after {
      max-height: 100%; }
  .btn-load .icon-reload {
    display: inline-block;
    height: 1.2rem;
    width: 1.2rem;
    vertical-align: middle; }
    .btn-load .icon-reload:before {
      content: '';
      display: block;
      height: 1.2rem;
      width: 1.2rem;
      vertical-align: middle;
      background-image: url("../img/vectors/icon-reload.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain; }
  .btn-load:hover, .btn-load:active {
    color: #fff;
    background-color: #067dc2; }

label {
  font-size: .65rem;
  font-family: "Exo", sans-serif;
  letter-spacing: .1em;
  text-transform: uppercase;
  line-height: 1.3; }
  label span {
    margin: 0 1rem .5rem;
    display: block; }

input {
  padding: 0.6rem 1.25rem;
  width: 100%;
  font-size: .8rem;
  text-align: center;
  letter-spacing: .05em;
  background-color: #f8f9fa;
  border: none;
  box-shadow: none; }
  input::placeholder {
    color: transparent; }
  input[type="checkbox"] {
    width: 1rem; }

textarea {
  padding: .6rem 1.25rem;
  width: 100%;
  font-size: .8rem;
  letter-spacing: .05em;
  background-color: #f8f9fa;
  border: none;
  box-shadow: none; }
  textarea::placeholder {
    text-transform: uppercase; }

body.lg-on {
  overflow: hidden; }

.lg-backdrop {
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(0.8rem); }

.lg-outer .lg-inner {
  height: 85%;
  top: 50%;
  transform: translateY(-50%); }

.lg-outer .lg-thumb {
  margin: 0 auto; }
  .lg-outer .lg-thumb .lg-thumb-item {
    border: none;
    border-radius: 0;
    transition: transform .4s ease, opacity .7s ease; }
    .lg-outer .lg-thumb .lg-thumb-item:not(.active) {
      opacity: .1;
      transform: scale(0.92); }
    .lg-outer .lg-thumb .lg-thumb-item.active, .lg-outer .lg-thumb .lg-thumb-item:hover {
      border-color: inherit; }

.lg-outer .lg-toolbar {
  background-color: transparent; }
  .lg-outer .lg-toolbar .lg-icon {
    color: #fff;
    font-size: 1rem; }
  .lg-outer .lg-toolbar .lg-autoplay-button:after {
    content: '\e941';
    display: inline-block;
    transform: rotate(-90deg); }
  .lg-outer .lg-toolbar #lg-counter {
    position: absolute;
    padding-left: 0;
    width: 100%;
    left: 0;
    color: #fff;
    text-align: center;
    pointer-events: none; }
  .lg-outer .lg-toolbar #lg-zoom-in,
  .lg-outer .lg-toolbar #lg-zoom-out {
    float: left; }

.lg-outer .lg-actions .lg-icon {
  font-family: 'Girolibero';
  font-size: 1rem; }

.lg-outer .lg-actions .lg-next {
  right: 0; }
  .lg-outer .lg-actions .lg-next:after {
    display: none; }
  .lg-outer .lg-actions .lg-next.disabled {
    opacity: 1;
    transform: translateX(110%); }

.lg-outer .lg-actions .lg-prev {
  left: 0; }
  .lg-outer .lg-actions .lg-prev:before {
    transform: rotate(180deg); }
  .lg-outer .lg-actions .lg-prev:after {
    display: none; }
  .lg-outer .lg-actions .lg-prev.disabled {
    opacity: 1;
    transform: translateX(-110%); }

.lg-outer .lg-progress-bar {
  top: inherit;
  bottom: 0; }
  .lg-outer .lg-progress-bar .lg-progress {
    background-color: #007bff; }

.lg-outer .lg-sub-html {
  background-color: rgba(0, 0, 0, 0); }
  .lg-outer .lg-sub-html.expanded {
    background-color: rgba(0, 0, 0, 0.5); }

#lg-actual-size,
#lg-share,
.lg-fullscreen,
.lg-download {
  display: none; }

.video-player {
  display: flex;
  position: relative;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #000;
  overflow: hidden;
  z-index: 1; }
  .video-player video {
    max-width: 100%;
    z-index: 1; }
  .video-player .video-topbar {
    display: flex;
    position: absolute;
    margin: auto;
    bottom: 9rem;
    color: #fff;
    font-weight: bold;
    line-height: 1;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: all .5s ease; }
    .video-player .video-topbar .caption {
      margin-left: .5em;
      font-weight: normal; }
  .video-player .video-controls {
    position: absolute;
    top: 1rem;
    max-width: 100%;
    color: #fff;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(transparent 4px, rgba(255, 255, 255, 0.3) 4px);
    backdrop-filter: blur(0.8rem);
    border-radius: .2em;
    box-shadow: 0 1em 0.5rem -1rem rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 2;
    transition: transform .5s ease, opacity .7s ease; }
    @media (max-width: 767.98px) {
      .video-player .video-controls {
        top: auto;
        bottom: 5px;
        right: 5px;
        left: 5px; } }
    .video-player .video-controls .seekbar {
      position: relative;
      width: 100%;
      height: 4px;
      background-color: rgba(255, 255, 255, 0.2);
      overflow: hidden;
      cursor: pointer; }
      .video-player .video-controls .seekbar .progress {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        background-color: rgba(255, 255, 255, 0.8);
        transition: all .25s linear; }
    .video-player .video-controls .control-btn {
      display: flex;
      position: relative;
      padding: 0;
      height: 45px;
      min-height: 45px;
      width: 45px;
      min-width: 45px;
      color: #fff;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.25);
      border: 0;
      outline: 0;
      transition: background-color .4s ease;
      cursor: pointer; }
      .video-player .video-controls .control-btn i {
        position: absolute; }
      .video-player .video-controls .control-btn:hover {
        background-color: rgba(255, 255, 255, 0.25); }
    .video-player .video-controls .video-playback-controls {
      display: flex;
      height: 45px;
      align-items: center; }
      .video-player .video-controls .video-playback-controls .video-title {
        margin: 0 1.2rem;
        max-width: 280px;
        font-size: .8rem;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transform: translateY(0.5rem);
        opacity: 0;
        transition: opacity .4s ease, transform .4s ease; }
        .video-player .video-controls .video-playback-controls .video-title:hover {
          opacity: 1;
          transform: translateY(0); }
        .video-player .video-controls .video-playback-controls .video-title .caption {
          margin-left: .5em;
          font-weight: normal; }
      .video-player .video-controls .video-playback-controls .toggle-play-pause.play .pause-icon {
        display: none; }
      .video-player .video-controls .video-playback-controls .toggle-play-pause.pause .play-icon {
        display: none; }
      .video-player .video-controls .video-playback-controls .toggle-play-pause:hover {
        background-color: rgba(255, 255, 255, 0.35); }
      .video-player .video-controls .video-playback-controls .toggle-play-pause:active {
        background-color: #056DA9; }
      .video-player .video-controls .video-playback-controls .toggle-volume {
        margin-right: .5rem;
        background-color: rgba(255, 255, 255, 0); }
        .video-player .video-controls .video-playback-controls .toggle-volume:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          height: 3px;
          width: 40%;
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 4px;
          transform: translateY(100%);
          transition: transform .7s ease; }
        .video-player .video-controls .video-playback-controls .toggle-volume.on .volume-off {
          display: none; }
        .video-player .video-controls .video-playback-controls .toggle-volume.off .volume-on {
          display: none; }
        .video-player .video-controls .video-playback-controls .toggle-volume.off:after {
          transform: translateY(0); }
      .video-player .video-controls .video-playback-controls .video-volume-control {
        display: flex;
        margin-right: .5rem;
        align-items: center;
        height: 2px;
        width: 120px; }
        @media (max-width: 767.98px) {
          .video-player .video-controls .video-playback-controls .video-volume-control {
            width: 400px; } }
        .video-player .video-controls .video-playback-controls .video-volume-control .volume-seekbar {
          width: 100%;
          transition: width .7s ease; }
          .video-player .video-controls .video-playback-controls .video-volume-control .volume-seekbar.disabled {
            width: 0; }
      .video-player .video-controls .video-playback-controls .video-timing {
        display: flex;
        margin-right: .5rem;
        margin-left: auto;
        align-items: center;
        justify-content: center;
        font-size: .8rem; }
        .video-player .video-controls .video-playback-controls .video-timing .time {
          width: 50px;
          text-align: center; }
          .video-player .video-controls .video-playback-controls .video-timing .time.start-time {
            display: none; }
          .video-player .video-controls .video-playback-controls .video-timing .time.end-time {
            font-family: sans-serif;
            padding: 0 .5em;
            color: rgba(255, 255, 255, 0.7);
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 1em; }
  .video-player .play-on-stop {
    position: absolute;
    z-index: 2;
    opacity: 1; }
    .video-player .play-on-stop button {
      display: flex;
      position: relative;
      padding: 0;
      height: 5rem;
      min-height: 5rem;
      width: 5rem;
      min-width: 5rem;
      color: #fff;
      font-size: 6rem;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      border: 0;
      border-radius: 50%;
      overflow: hidden;
      outline: 0;
      z-index: 2;
      transition: background-color .4s ease, opacity .4s ease, transform .4s ease;
      cursor: pointer; }
      .video-player .play-on-stop button i {
        position: absolute; }
      .video-player .play-on-stop button:hover {
        background-color: rgba(255, 255, 255, 0.25); }
  .video-player.play .play-on-stop button {
    opacity: 0;
    pointer-events: none; }
  .video-player.pause .play-on-stop button {
    opacity: 1; }
  .video-player.pause .video-controls {
    opacity: 0;
    transform: translateY(-100%); }
    @media (max-width: 767.98px) {
      .video-player.pause .video-controls {
        transform: translateY(100%); } }
  .video-player.hide-controls .play-on-stop button {
    opacity: 0; }
  .video-player.hide-controls .video-controls {
    opacity: 0;
    transform: translateY(-100%); }
    @media (max-width: 767.98px) {
      .video-player.hide-controls .video-controls {
        transform: translateY(100%); } }

.container-booking-form {
  max-width: 360px;
  background: #fff;
  margin-top: -4rem;
  padding: 1.4rem .8rem; }
  @media (max-width: 991.98px) {
    .container-booking-form {
      margin-top: -1rem;
      max-width: inherit; } }
  .container-booking-form .title {
    font-size: 1.1rem;
    letter-spacing: .05em; }
  .container-booking-form img {
    width: 1rem; }
  .container-booking-form div {
    align-self: center; }
  .container-booking-form label {
    margin-bottom: 0;
    font-size: .65rem;
    font-family: "Exo", sans-serif;
    text-transform: uppercase;
    line-height: 1.3; }
    .container-booking-form label span {
      margin-top: 0.5rem;
      display: block; }
  .container-booking-form input {
    background: #f2f2f2;
    padding: 0.6rem 1.25rem;
    border: none;
    box-shadow: none;
    max-width: 170px;
    font-size: 0.8rem;
    text-align: center; }
  .container-booking-form .cta-book {
    margin-left: -.8rem;
    margin-right: -.8rem; }
    .container-booking-form .cta-book .button-book-room {
      position: relative;
      background: #def0fb;
      color: #056da9;
      border: none;
      font-size: 0.75rem;
      text-decoration: none;
      padding: 1.125em 1.4rem;
      width: 100%;
      text-align: left;
      -webkit-appearance: none; }
      .container-booking-form .cta-book .button-book-room:hover {
        color: #068ddb; }
        .container-booking-form .cta-book .button-book-room:hover .arrow-btn img {
          transform: translateX(10%); }
      .container-booking-form .cta-book .button-book-room .arrow-btn {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 1.2em 1.3em;
        background: #056DA9; }
        .container-booking-form .cta-book .button-book-room .arrow-btn img {
          width: 100%;
          transition: transform .4s ease; }

.macro-description .caption {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Trajan", serif;
  font-size: 1rem;
  letter-spacing: 0.75rem; }

.macro-description .title {
  font-family: "Exo", sans-serif;
  font-size: 1.375rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  color: #343a40; }

.macro-description .description {
  margin: 0;
  color: #343a40; }

.tease {
  cursor: pointer;
  transition: box-shadow .4s ease, opacity .4s ease; }
  .tease a {
    display: block;
    color: inherit;
    text-decoration: none;
    border: 1px solid rgba(233, 236, 239, 0.8); }
  @media (max-width: 991.98px) {
    .tease:nth-of-type(even) {
      margin-top: inherit; } }
  .tease .featured-image {
    display: block;
    position: relative;
    height: 14.3rem;
    width: 100%;
    background-color: #e9ecef;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    transition: background-position .4s ease; }
    .tease .featured-image span {
      position: absolute;
      right: .8rem;
      bottom: .8rem;
      color: #fff;
      font-size: 2rem;
      font-weight: 300;
      line-height: .8;
      z-index: 1;
      transition: transform .4s ease; }
      .tease .featured-image span:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.8rem;
        height: 1.8rem;
        transform: translate(-50%, -52%);
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        opacity: 0;
        z-index: 1;
        transition: opacity .4s ease; }
    .tease .featured-image:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 0;
      transition: height .4s ease; }
  .tease .content {
    background-color: #fff;
    padding: 2.25rem 1.75rem;
    pointer-events: none; }
    .tease .content .date {
      display: inline-block;
      padding: .25em .5em;
      color: #6c757d;
      font-size: .8em;
      background-color: #e9ecef; }
    .tease .content .location {
      display: inline-block;
      padding: .25em .5em;
      color: #fff;
      font-size: .8em;
      background-color: #056DA9; }
    .tease .content h4 {
      font-size: 1.2rem;
      letter-spacing: .1em; }
      @media (min-width: 992px) {
        .tease .content h4 {
          max-width: 90%; } }
    .tease .content p {
      font-family: 'Exo',serif;
      font-size: 0.9rem;
      font-weight: 300;
      text-align: left !important; }
  .tease:hover .featured-image {
    background-position: 50% 100%; }
    .tease:hover .featured-image span {
      transform: rotate(90deg); }
      .tease:hover .featured-image span:after {
        opacity: 1; }
    .tease:hover .featured-image:after {
      height: 100%; }

.intro-header-article {
  color: #fff; }
  @media (max-width: 991.98px) {
    .intro-header-article {
      display: none; } }
  .intro-header-article:after {
    content: '';
    height: 1px;
    width: 0;
    transition: 1.5s all;
    background-color: #fff;
    position: absolute;
    left: -7.5rem;
    margin-top: 1.25rem; }
  .intro-header-article span {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.75rem;
    font-family: "Trajan"; }
  .intro-header-article p {
    font-family: "Trajan";
    font-size: 0.87rem;
    text-transform: uppercase;
    letter-spacing: 0.18rem; }

.article-description {
  color: #fff; }
  .article-description span {
    font-size: 1.3rem; }
    @media (max-width: 767.98px) {
      .article-description span {
        font-size: 1rem; } }
  .article-description h1 {
    font-size: 7vh; }
    @media (max-width: 767.98px) {
      .article-description h1 {
        font-size: 2.6rem; } }
  .article-description p {
    max-width: 70%;
    font-size: 1.12rem; }
    .article-description p a {
      display: none; }
    @media (max-width: 767.98px) {
      .article-description p {
        font-size: 0.9rem;
        max-width: 100%; } }

.page-home li {
  list-style: none; }

.page-home ul, .page-home ol, .page-home li {
  margin: 0;
  padding: 0; }

.page-home #external-links {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 9;
  transition: transform 1s ease, background-color .4s ease; }
  @media (max-width: 767.98px) {
    .page-home #external-links {
      bottom: 1rem;
      right: 1rem; } }
  .page-home #external-links .label {
    color: #fff;
    font-size: .75em;
    background-color: #2b83b6;
    border-color: #2b83b6; }
    .page-home #external-links .label:hover {
      background-color: #1e7cb2;
      border-color: #1e7cb2; }
  .page-home #external-links.active {
    transform: translateX(-4rem); }

@media (min-width: 600px) and (max-height: 650px) {
  #newFullSlider .text-container {
    margin-bottom: -100px; } }

@media (min-width: 500px) and (max-height: 600px) {
  #newFullSlider .text-container {
    display: none; }
  .btn-booking {
    display: none !important; } }

#slidesThumbnails.slick-initialized {
  /*display: block !important;*/ }

#slidesCaptions.slick-initialized {
  display: block !important; }

.page-archive .disclaimer {
  color: #056da9;
  font-family: "Exo";
  background-color: #e6f0f6; }

.page-article p {
  margin-bottom: 1rem; }
  .page-article p img {
    width: 100% !important; }

.page-article .rp4wp-related-post,
.page-article .rp4wp-related-posts {
  padding: 2rem 0; }
  .page-article .rp4wp-related-post h3,
  .page-article .rp4wp-related-posts h3 {
    position: relative;
    margin: 5rem 0 2rem; }
    .page-article .rp4wp-related-post h3:after,
    .page-article .rp4wp-related-posts h3:after {
      content: '';
      display: block;
      position: relative;
      margin-top: 1rem;
      height: 1px;
      width: 30%;
      float: right;
      background-color: #000; }
  .page-article .rp4wp-related-post .rp4wp-posts-list,
  .page-article .rp4wp-related-posts .rp4wp-posts-list {
    padding: 0; }
    .page-article .rp4wp-related-post .rp4wp-posts-list li,
    .page-article .rp4wp-related-posts .rp4wp-posts-list li {
      position: relative;
      display: flex;
      margin: 0;
      padding: 0;
      height: 200px;
      width: 33.3%;
      color: #000;
      justify-content: center;
      flex-direction: column;
      background-color: #f8f9fa;
      border: 0.5rem solid #fff; }
      .page-article .rp4wp-related-post .rp4wp-posts-list li:before,
      .page-article .rp4wp-related-posts .rp4wp-posts-list li:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background-image: linear-gradient(rgba(248, 249, 250, 0) 5%, #f8f9fa 85%);
        pointer-events: none; }
      @media (max-width: 767.98px) {
        .page-article .rp4wp-related-post .rp4wp-posts-list li,
        .page-article .rp4wp-related-posts .rp4wp-posts-list li {
          width: 100%; } }
      .page-article .rp4wp-related-post .rp4wp-posts-list li .rp4wp_component_image,
      .page-article .rp4wp-related-posts .rp4wp-posts-list li .rp4wp_component_image {
        display: none;
        order: 1; }
      .page-article .rp4wp-related-post .rp4wp-posts-list li .rp4wp_component_title,
      .page-article .rp4wp-related-posts .rp4wp-posts-list li .rp4wp_component_title {
        order: 2;
        padding: 0 1rem;
        font-size: 1.2rem;
        font-family: "Trajan";
        font-weight: 300;
        line-height: 1;
        overflow: visible; }
        .page-article .rp4wp-related-post .rp4wp-posts-list li .rp4wp_component_title a,
        .page-article .rp4wp-related-posts .rp4wp-posts-list li .rp4wp_component_title a {
          display: block;
          position: relative;
          top: 50%;
          color: inherit;
          font-weight: 400;
          transform: translateY(-50%); }
      .page-article .rp4wp-related-post .rp4wp-posts-list li .rp4wp_component_excerpt,
      .page-article .rp4wp-related-posts .rp4wp-posts-list li .rp4wp_component_excerpt {
        order: 3;
        padding: 1rem;
        min-height: 110px; }
        .page-article .rp4wp-related-post .rp4wp-posts-list li .rp4wp_component_excerpt p,
        .page-article .rp4wp-related-posts .rp4wp-posts-list li .rp4wp_component_excerpt p {
          font-size: .9rem;
          letter-spacing: 0; }

@media (max-width: 767.98px) {
  .page-list header #btnBackTop.active {
    transform: translateY(-3rem); }
    .page-list header #btnBackTop.active:hover {
      transform: translateY(-3.5rem); } }

.page-list #filters {
  height: fit-content; }
  @media (max-width: 767.98px) {
    .page-list #filters {
      position: fixed;
      padding-left: -1rem;
      height: 100vh;
      bottom: 0;
      background-color: #fff;
      overflow: auto;
      box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.5);
      transform: translateY(calc(100% - 50px));
      transition: transform .4s ease;
      z-index: 100; }
      .page-list #filters.active {
        transform: translateY(0); }
        .page-list #filters.active h6 {
          border-bottom: 1px solid #dee2e6; }
          .page-list #filters.active h6 i {
            transform: rotate(180deg); } }
  .page-list #filters h6 {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    font-family: "Exo", sans-serif;
    font-size: .9rem;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #fff;
    border-bottom: 1px solid #dee2e6;
    z-index: 1;
    pointer-events: none; }
    @media (max-width: 767.98px) {
      .page-list #filters h6 {
        display: block;
        padding: 1rem;
        height: 50px;
        border-bottom: 1px solid transparent;
        pointer-events: all;
        cursor: pointer; } }
    .page-list #filters h6 .filter-count {
      font-weight: 300;
      text-transform: lowercase;
      opacity: 0;
      pointer-events: none;
      transition: opacity .7s ease; }
      .page-list #filters h6 .filter-count strong {
        font-weight: 700; }
      .page-list #filters h6 .filter-count.active {
        opacity: 1; }
  .page-list #filters .filter-input {
    position: relative;
    display: block;
    width: 100%;
    font-size: .9rem;
    cursor: pointer; }
    .page-list #filters .filter-input:hover {
      background-color: #f8f9fa; }
    .page-list #filters .filter-input input {
      width: 0;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      pointer-events: none; }
    .page-list #filters .filter-input.checked span:after {
      opacity: 1; }
    @media (max-width: 767.98px) {
      .page-list #filters .filter-input span {
        margin: .5rem 0; } }
    .page-list #filters .filter-input span.checked:after {
      opacity: 1; }
    .page-list #filters .filter-input span:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 1em;
      height: .8rem;
      width: .8rem;
      background-image: url("../img/vectors/icon-tick.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transform: translateY(-50%);
      opacity: .1; }
    .page-list #filters .filter-input input:checked + span:after {
      opacity: 1; }
  .page-list #filters .filters-actions {
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    transform: translateY(100%);
    opacity: 0;
    overflow: hidden;
    transition: transform .7s ease, opacity .7s ease; }
    @media (min-width: 576px) {
      .page-list #filters .filters-actions {
        margin-left: 0;
        margin-right: 0; } }
    .page-list #filters .filters-actions.active {
      opacity: 1;
      transform: translateY(0); }
    .page-list #filters .filters-actions .btn-filter {
      display: block;
      padding: .5em;
      width: 100%;
      color: #ced4da;
      text-align: center;
      background-color: #e9ecef;
      border-radius: 0; }
      @media (max-width: 767.98px) {
        .page-list #filters .filters-actions .btn-filter {
          padding: .75em; } }
    .page-list #filters .filters-actions .btn-apply {
      color: #fff;
      background-color: #056DA9; }

.page-list #vam-posts {
  margin: 0;
  position: relative;
  z-index: 1; }
  .page-list #vam-posts .result {
    font-size: .9rem; }
    .page-list #vam-posts .result .result-count {
      color: #056DA9;
      font-weight: 600; }
      @media (max-width: 991.98px) {
        .page-list #vam-posts .result .result-count {
          width: 100%; } }
    .page-list #vam-posts .result .result-active-filters {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      flex-wrap: wrap; }
      .page-list #vam-posts .result .result-active-filters button {
        color: #adb5bd;
        font-size: .9em;
        background-color: transparent;
        border: 1px solid #dee2e6;
        transition: color .4s ease, border .4s ease; }
        .page-list #vam-posts .result .result-active-filters button i {
          font-size: .25em;
          margin-left: .5em; }
        .page-list #vam-posts .result .result-active-filters button:hover, .page-list #vam-posts .result .result-active-filters button:active {
          color: #6c757d;
          border: 1px solid #6c757d; }
  .page-list #vam-posts article {
    cursor: pointer;
    transition: box-shadow .4s ease, opacity .4s ease; }
    @media (min-width: 768px) {
      .page-list #vam-posts article {
        max-width: 300px; } }
    .page-list #vam-posts article a {
      color: inherit;
      text-decoration: none; }
    .page-list #vam-posts article:nth-of-type(even) {
      margin-top: 3rem; }
      @media (max-width: 991.98px) {
        .page-list #vam-posts article:nth-of-type(even) {
          margin-top: inherit; } }
    .page-list #vam-posts article .featured-image {
      display: block;
      position: relative;
      height: 14.3rem;
      width: 100%;
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      transition: background-position .4s ease; }
      .page-list #vam-posts article .featured-image span {
        position: absolute;
        right: .8rem;
        bottom: .8rem;
        color: #fff;
        font-size: 2rem;
        font-weight: 300;
        line-height: .8;
        z-index: 1;
        transition: transform .4s ease; }
        .page-list #vam-posts article .featured-image span:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 1.8rem;
          height: 1.8rem;
          transform: translate(-50%, -52%);
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 50%;
          opacity: 0;
          z-index: 1;
          transition: opacity .4s ease; }
      .page-list #vam-posts article .featured-image:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 0;
        transition: height .4s ease; }
    .page-list #vam-posts article .content {
      background-color: #fff;
      padding: 2.25rem 1.75rem;
      pointer-events: none; }
      .page-list #vam-posts article .content h4 {
        font-size: 1.2rem;
        letter-spacing: .1em; }
        @media (min-width: 992px) {
          .page-list #vam-posts article .content h4 {
            max-width: 90%; } }
      .page-list #vam-posts article .content p {
        font-family: 'Exo',serif;
        font-size: 0.9rem;
        font-weight: 300;
        text-align: left !important; }
    .page-list #vam-posts article:hover .featured-image {
      background-position: 50% 100%; }
      .page-list #vam-posts article:hover .featured-image span {
        transform: rotate(90deg); }
        .page-list #vam-posts article:hover .featured-image span:after {
          opacity: 1; }
      .page-list #vam-posts article:hover .featured-image:after {
        height: 100%; }

.page-contacts #contact-form .turistic-contacts {
  line-height: 1; }
  .page-contacts #contact-form .turistic-contacts .contact:not(:last-child) {
    border-bottom: 1px solid #e9ecef; }

@media (min-width: 576px) {
  .page-contacts #contact-form .form-box {
    margin-top: -5rem; } }

.page-info .tile-info {
  height: 350px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #056DA9;
  border: 0.5rem solid #fff;
  overflow: hidden; }
  .page-info .tile-info a {
    position: relative;
    height: 100%;
    color: #fff;
    text-decoration: none;
    transition: all .4s ease; }
    .page-info .tile-info a:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 200%;
      width: 100%;
      max-height: 100%;
      background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65));
      z-index: 0;
      transition: max-height .4s ease; }
    .page-info .tile-info a .content {
      z-index: 1; }
      .page-info .tile-info a .content h3 {
        margin-bottom: 0; }
      .page-info .tile-info a .content label {
        display: inline-block;
        padding: .5em 1em;
        color: #343a40;
        font-weight: bold;
        background-color: #fff;
        transition: color .4s ease, background-color .4s ease; }
    .page-info .tile-info a span {
      position: absolute;
      top: 1rem;
      left: 1rem;
      color: #fff;
      font-size: 2rem;
      font-weight: 300;
      line-height: .8;
      z-index: 2;
      transform: translateY(calc(-100% - 1rem));
      transition: transform .4s ease; }
      .page-info .tile-info a span:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.8rem;
        height: 1.8rem;
        transform: translate(-50%, -52%);
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 50%;
        opacity: 0;
        z-index: 1;
        transition: opacity .4s ease; }
    .page-info .tile-info a:hover:after {
      max-height: 200%; }
    .page-info .tile-info a:hover .content label {
      color: #fff;
      background-color: #056DA9; }
    .page-info .tile-info a:hover span {
      transform: translateY(0) rotate(90deg); }
      .page-info .tile-info a:hover span:after {
        opacity: 1; }

.page-come-arrivare .transport .title {
  padding: .5em;
  letter-spacing: .1em;
  border-bottom: 1px solid #e9ecef; }

.page-come-arrivare .transport .step {
  padding: 1em .5em; }
  .page-come-arrivare .transport .step h6 {
    font-family: "Exo";
    font-size: .8rem;
    font-weight: bold;
    text-transform: uppercase; }

.page-come-arrivare .map {
  position: relative;
  z-index: 1; }
  .page-come-arrivare .map iframe {
    width: 100%; }

.page-macroarea.foodwine .main-event {
  margin-top: -5rem; }

.page-landing.visit-vo .main-event {
  margin-top: -6rem;
  padding-bottom: 2rem; }
  @media (max-width: 767.98px) {
    .page-landing.visit-vo .main-event {
      margin-top: .5rem; } }

@media (min-width: 576px) {
  .page-landing.visit-vo #contact-form {
    margin-top: -6rem; } }

.fbc-items {
  font-family: Exo, sans-serif !important; }
  .fbc-items .fa {
    display: none; }

.article-breadcrumb nav {
  border-bottom: 1px solid #D8D8D8; }
  .article-breadcrumb nav ol {
    background-color: #fff;
    margin: 0; }
    .article-breadcrumb nav ol .breadcrumb-item {
      text-transform: uppercase;
      color: #000; }
      .article-breadcrumb nav ol .breadcrumb-item a {
        color: #B8B8B8; }
      .article-breadcrumb nav ol .breadcrumb-item + .breadcrumb-item::before {
        content: '>'; }
    .article-breadcrumb nav ol .macro-category {
      position: absolute;
      right: 30px;
      font-family: "Trajan";
      letter-spacing: 0.75rem;
      font-weight: 700; }
      @media (max-width: 767.98px) {
        .article-breadcrumb nav ol .macro-category {
          display: none; } }

.page-login {
  overflow: hidden; }
  .page-login #lottie {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .page-login #lottie canvas:last-child {
      display: none; }
  .page-login #main-content {
    margin: 0; }
  .page-login #login {
    height: 100vh;
    background-color: #e9ecef;
    background-image: url("../img/water-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  .page-login .box-login {
    height: 100vh;
    background-color: #fff;
    overflow: hidden;
    width: 50%;
    transform-origin: right;
    transition: width .7s ease; }
    @media (max-width: 767.98px) {
      .page-login .box-login {
        height: inherit; } }
  .page-login .form-login {
    max-width: 400px;
    font-size: .9rem;
    background-color: #fff;
    border-radius: .3rem; }
    .page-login .form-login .logo {
      max-width: 250px; }
    .page-login .form-login #loginform {
      padding: .5em 0;
      border-radius: .5rem; }
      .page-login .form-login #loginform p {
        margin: 1em 0;
        display: block; }
        .page-login .form-login #loginform p label {
          display: inline-block;
          font-size: .85rem;
          font-weight: 400;
          text-transform: none;
          letter-spacing: normal; }
        .page-login .form-login #loginform p.login-remember label {
          color: #6c757d;
          font-weight: 300;
          text-align: right; }
          .page-login .form-login #loginform p.login-remember label input {
            margin-right: .5rem; }
    .page-login .form-login small,
    .page-login .form-login .captcha {
      font-size: .8rem;
      text-transform: none;
      letter-spacing: normal; }
  .page-login .claim {
    color: #fff;
    font-size: 1rem;
    max-width: 500px;
    z-index: 1; }
    @media (max-width: 767.98px) {
      .page-login .claim h3 {
        font-size: 1.4rem; } }
  .page-login input {
    width: 100%;
    transition: border .4s ease; }
    .page-login input[type="text"], .page-login input[type="password"] {
      padding: .6rem;
      font-size: .9rem;
      border: 1px solid #dee2e6;
      border-radius: .2rem; }
    .page-login input[type="checkbox"] {
      width: inherit; }
    .page-login input[type="submit"] {
      padding: .8em 1.4rem;
      color: #fff;
      font-family: 'Exo',serif;
      font-size: .8rem;
      letter-spacing: .15em;
      text-transform: uppercase;
      border: 1px solid #fff;
      background-color: #056da9;
      -webkit-transition: .4s all;
      transition: .4s all;
      text-decoration: none;
      width: 100%;
      min-width: 200px; }
      .page-login input[type="submit"]:hover {
        background-color: #068ddb; }
    .page-login input:focus {
      outline: inherit;
      border: 1px solid #007bff; }
  .page-login footer {
    border: none; }

.app_root {
  font-family: 'Exo', sans-serif !important;
  margin: 0 auto !important; }

.tabbar.tabbar--level-1,
.page__toolbar,
.categories {
  display: none; }

.mdc-checkbox::before, .mdc-checkbox::after {
  background-color: #056DA9 !important; }

.sidebar-widget__input-addon:hover {
  color: #056DA9 !important; }

.datepicker__head-control:hover {
  color: #056DA9 !important; }

.datepicker__table-item.datepicker__table-item--active .datepicker__table-item-inner:after {
  border-bottom: 3px solid #056DA9 !important; }

.resultsheader__sorting-badge.resultsheader__sorting-badge--active {
  background-color: #056DA9 !important;
  border-color: #056DA9 !important; }

.resultsheader__sorting-badge:not(.resultsheader__sorting-badge--active):hover {
  color: #056DA9 !important;
  border-color: #056DA9 !important; }

.mdc-button.mdc-button--secondary:not(:disabled) {
  color: #056DA9 !important; }

.mdc-button.mdc-button--secondary::before,
.mdc-button.mdc-button--secondary::after {
  background-color: #056DA9 !important; }

.mdc-button--raised.mdc-button--secondary:not(:disabled) {
  color: #FFF !important;
  background-color: #056DA9 !important; }

.offer-big-card:hover .offer-big-card__title {
  color: #056DA9 !important; }

.mapview .marker-cluster {
  background-color: rgba(5, 109, 169, 0.5) !important; }
  .mapview .marker-cluster div {
    background-color: #056DA9 !important; }

.mapview .htmlmarker-default.htmlicon > span {
  background-color: #056DA9 !important; }

.mapview .htmlmarker-default.htmlicon > span > .rating {
  color: #FFF !important; }

.mapview .htmlmarker-default.htmlicon::before {
  background-color: #056DA9 !important; }

.mapview .htmlmarker-active.htmlicon {
  color: #056DA9 !important;
  border: 1px solid #056DA9 !important; }

.mapview .htmlmarker-active.htmlicon::before {
  border: 1px solid #056DA9 !important; }

.mapview .htmlmarker-active.htmlicon > span > .rating {
  color: #056DA9 !important;
  border-left: 1px solid #056DA9 !important;
  border-right: 1px solid #056DA9 !important; }

.mapview .htmlmarker-visited.htmlicon {
  background-color: #056DA9 !important; }

.mapview .htmlmarker-visited.htmlicon > span {
  background-color: #056DA9 !important; }

.mapview .htmlmarker-visited.htmlicon > span > .rating {
  color: #FFF !important; }

.mapview .htmlmarker-visited.htmlicon::before {
  background-color: #056DA9 !important; }

.pagination__item--active .pagination__item-link {
  background-color: #056DA9 !important; }

.detail-page .scrollspy {
  color: #056DA9 !important;
  letter-spacing: .1em;
  text-transform: uppercase;
  background-color: #eef9ff !important;
  z-index: 8 !important; }

.detail-page .mapview {
  z-index: 7 !important; }
  .detail-page .mapview .gen-iconmarker--big .gen-iconmarker--highlight-1 {
    fill: #056DA9 !important;
    stroke: #056DA9 !important; }
  .detail-page .mapview .gen-iconmarker--big .gen-iconmarker--highlight-2 {
    fill: #FFF !important; }
  .detail-page .mapview .gen-iconmarker--highlight.gen-iconmarker--label {
    color: #056DA9 !important;
    border: 1px solid #056DA9 !important; }

.high-contrast * {
  color: #FFFF00; }

.high-contrast body,
.high-contrast header,
.high-contrast section,
.high-contrast input,
.high-contrast footer {
  color: #FFFF00 !important;
  background-color: #000 !important; }

.high-contrast input,
.high-contrast textarea {
  color: #FFFF00 !important;
  border: 1px #FFFF00 solid;
  background-color: #000 !important; }
  .high-contrast input::placeholder,
  .high-contrast textarea::placeholder {
    color: #FFFF00 !important; }

.high-contrast h1,
.high-contrast h2,
.high-contrast h3,
.high-contrast h4,
.high-contrast h5,
.high-contrast h6,
.high-contrast ul,
.high-contrast li,
.high-contrast a,
.high-contrast button,
.high-contrast p {
  color: #FFFF00 !important; }

.high-contrast .btn-default {
  color: #FFFF00 !important;
  border-color: #FFFF00 !important; }
  .high-contrast .btn-default:after {
    background-color: #FFFF00 !important; }
  .high-contrast .btn-default:hover {
    color: #000 !important;
    border-color: #FFFF00 !important; }

.high-contrast .btn-blue {
  color: #000 !important;
  background-color: #FFFF00 !important;
  border-color: #FFFF00 !important; }
  .high-contrast .btn-blue:after {
    background-color: #FFFF00 !important; }
  .high-contrast .btn-blue:hover {
    color: #000;
    border-color: #FFFF00 !important; }

.high-contrast .btn-arrow, .high-contrast .more-events .general-events .tns-controls [data-controls="prev"], .more-events .general-events .tns-controls .high-contrast [data-controls="prev"],
.high-contrast .more-events .general-events .tns-controls [data-controls="next"], .more-events .general-events .tns-controls .high-contrast [data-controls="next"], .high-contrast #media-slider .nav-container button, #media-slider .nav-container .high-contrast button, .high-contrast .lg-outer .lg-actions .lg-next, .lg-outer .lg-actions .high-contrast .lg-next, .high-contrast .lg-outer .lg-actions .lg-prev, .lg-outer .lg-actions .high-contrast .lg-prev {
  color: #000 !important;
  background-color: #FFFF00 !important;
  border-color: #FFFF00 !important; }
  .high-contrast .btn-arrow:before, .high-contrast .more-events .general-events .tns-controls [data-controls="prev"]:before, .more-events .general-events .tns-controls .high-contrast [data-controls="prev"]:before,
  .high-contrast .more-events .general-events .tns-controls [data-controls="next"]:before, .more-events .general-events .tns-controls .high-contrast [data-controls="next"]:before, .high-contrast #media-slider .nav-container button:before, #media-slider .nav-container .high-contrast button:before, .high-contrast .lg-outer .lg-actions .lg-next:before, .lg-outer .lg-actions .high-contrast .lg-next:before, .high-contrast .lg-outer .lg-actions .lg-prev:before, .lg-outer .lg-actions .high-contrast .lg-prev:before {
    filter: invert(1); }

.high-contrast .btn-load {
  color: #000 !important;
  background-color: #FFFF00 !important;
  border-color: #FFFF00 !important; }
  .high-contrast .btn-load:before {
    filter: invert(1); }

.high-contrast video {
  display: none !important; }

.high-contrast img:not([src$="svg"]) {
  visibility: hidden;
  background-color: #000;
  border: 1px #FFFF00 solid; }

.high-contrast header .toggler-icon {
  background-color: #FFFF00; }

.high-contrast header #floatingMenuToggler {
  background-color: #FFFF00; }
  .high-contrast header #floatingMenuToggler .toggler-icon {
    background-color: #000; }

.high-contrast header #btnBackTop {
  background-color: #FFFF00; }
  .high-contrast header #btnBackTop:before {
    filter: invert(1); }

.high-contrast header #main-navigation {
  background-color: #000; }
  .high-contrast header #main-navigation #menu-closer {
    background-color: #FFFF00 !important;
    border-radius: 50%; }
    .high-contrast header #main-navigation #menu-closer img {
      filter: invert(1); }
  .high-contrast header #main-navigation .navigation-content {
    background-color: #000 !important;
    border-top: 1px #FFFF00 solid; }
    .high-contrast header #main-navigation .navigation-content .btn-search {
      color: #000 !important;
      background-color: #FFFF00 !important; }
    .high-contrast header #main-navigation .navigation-content .menu-logo {
      border-color: #FFFF00 !important;
      filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(-110deg) invert(1); }
      .high-contrast header #main-navigation .navigation-content .menu-logo img {
        filter: invert(1); }
    .high-contrast header #main-navigation .navigation-content .topic-image {
      border: none !important; }
    .high-contrast header #main-navigation .navigation-content .option-menu {
      border-color: #FFFF00 !important; }
    .high-contrast header #main-navigation .navigation-content .children-menu a.back-link {
      background-color: #FFFF00 !important; }
      .high-contrast header #main-navigation .navigation-content .children-menu a.back-link i {
        color: #000 !important; }
    .high-contrast header #main-navigation .navigation-content .children-menu a:after {
      background-color: blue;
      filter: invert(1); }

.high-contrast footer {
  padding-top: 1rem;
  background-color: #000;
  border-top: 1px #FFFF00 solid; }
  .high-contrast footer .first-column .option-menu {
    background-color: #000; }
    .high-contrast footer .first-column .option-menu .service-menu:after {
      border-bottom: 1px #FFFF00 solid; }
  .high-contrast footer .vam-line {
    display: none; }
  .high-contrast footer .landing .logo {
    filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(-110deg) invert(1); }

.high-contrast #newFullSlider .text-box-left img {
  filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(-110deg) invert(1); }

.high-contrast #newFullSlider #controlArrows button {
  filter: invert(1);
  background-color: blue !important;
  border: 1px blue solid; }
  .high-contrast #newFullSlider #controlArrows button:hover {
    background-color: !important; }

.high-contrast #newFullSlider .sliderDots li button {
  border: 1px #FFFF00 solid; }

.high-contrast #newFullSlider .sliderDots li.slick-active button {
  background-color: #FFFF00 !important; }

.high-contrast #newFullSlider .sliderDots li:hover button {
  background-color: #FFFF00 !important; }

.high-contrast #newFullSlider .slidesCaptions .btn-pagelink:before {
  background-color: #FFFF00 !important; }

.high-contrast #newFullSlider .thumbnails-tool .thumb-wrapper:before {
  background-color: #FFFF00 !important; }

.high-contrast #newFullSlider .thumbnails-tool .slick-slide {
  border: 1px #FFFF00 solid; }
  .high-contrast #newFullSlider .thumbnails-tool .slick-slide.slick-current {
    border: 5px #FFFF00 solid; }

.high-contrast #booking .container-booking-form {
  padding: 1.4rem .8rem 0;
  border: 1px #FFFF00 solid;
  background-color: #000 !important; }
  .high-contrast #booking .container-booking-form .cta-book {
    border-top: 1px #FFFF00 solid; }
    .high-contrast #booking .container-booking-form .cta-book .button-book-room {
      background-color: #000 !important; }

.high-contrast #gallery-carousel {
  display: none !important; }

.high-contrast .main-event {
  margin: inherit !important; }
  .high-contrast .main-event .container-img-event {
    border: 1px #FFFF00 solid; }
  .high-contrast .main-event .event-box {
    color: #FFFF00 !important;
    border: 1px #FFFF00 solid;
    background-color: #000 !important; }
    .high-contrast .main-event .event-box .caption {
      color: #FFFF00 !important; }

.high-contrast .more-events .official-portal .text-container:after {
  background-color: #FFFF00 !important; }

.high-contrast .more-events .general-events {
  background-image: none; }
  .high-contrast .more-events .general-events #carousel-events .item .featured-image {
    border: 1px #FFFF00 solid;
    background-image: none !important; }
    .high-contrast .more-events .general-events #carousel-events .item .featured-image span {
      color: #FFFF00; }
      .high-contrast .more-events .general-events #carousel-events .item .featured-image span:after {
        border: 1px #FFFF00 solid;
        background-color: transparent !important; }
  .high-contrast .more-events .general-events #carousel-events .item .content {
    border: 1px #FFFF00 solid;
    border-top: none;
    background-color: #000 !important; }

.high-contrast .media-gallery {
  background-image: none;
  border-top: 1px #FFFF00 solid;
  border-bottom: 1px #FFFF00 solid; }

.high-contrast #partners,
.high-contrast #single-video,
.high-contrast #media-slider,
.high-contrast .mansory {
  display: none; }

.high-contrast #hero,
.high-contrast #landing-hero,
.high-contrast #article-hero,
.high-contrast #service-hero {
  background-image: none !important;
  border-bottom: 1px #FFFF00 solid; }
  .high-contrast #hero img,
  .high-contrast #landing-hero img,
  .high-contrast #article-hero img,
  .high-contrast #service-hero img {
    filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(-110deg) invert(1); }
  .high-contrast #hero .row,
  .high-contrast #landing-hero .row,
  .high-contrast #article-hero .row,
  .high-contrast #service-hero .row {
    background-color: #000; }
  .high-contrast #hero .intro-header-article:after,
  .high-contrast #landing-hero .intro-header-article:after,
  .high-contrast #article-hero .intro-header-article:after,
  .high-contrast #service-hero .intro-header-article:after {
    background-color: #FFFF00; }

.high-contrast .text_1 .caption_left:before,
.high-contrast .text_1 .caption_right:before {
  background-color: #FFFF00; }

.high-contrast .features {
  border-top: 1px #FFFF00 solid;
  border-bottom: 1px #FFFF00 solid; }
  .high-contrast .features .item img {
    display: none; }
  .high-contrast .features .item .description {
    margin: inherit !important; }

.high-contrast .text_2 .description:before {
  background-color: #FFFF00; }

.high-contrast .text_3 .caption:before {
  background-color: #FFFF00; }

.high-contrast .features-list li {
  padding: 1em;
  width: 50%;
  float: left;
  color: #000 !important;
  background-color: #FFFF00;
  border-color: #000; }
  .high-contrast .features-list li i {
    color: #FFFF00;
    background-color: #000; }

.high-contrast .main-service .row {
  background-image: none !important;
  border: 1px #FFFF00 solid; }
  .high-contrast .main-service .row span {
    color: #FFFF00; }
    .high-contrast .main-service .row span:after {
      border: 1px #FFFF00 solid;
      background-color: transparent !important; }

.high-contrast .services .row {
  margin: 1rem 0;
  background-image: none !important;
  background-color: #000;
  border: 1px #FFFF00 solid; }
  .high-contrast .services .row span {
    color: #FFFF00; }
    .high-contrast .services .row span:after {
      border: 1px #FFFF00 solid;
      background-color: transparent !important; }
  .high-contrast .services .row:nth-child(odd) .content {
    border-left: 1px #FFFF00 solid;
    background-color: #000; }
  .high-contrast .services .row:nth-child(even) .content {
    border-right: 1px #FFFF00 solid;
    background-color: #000; }
  .high-contrast .services .row:last-child .content {
    border-left: 1px #FFFF00 solid;
    background-color: #000; }

.high-contrast .point-of-interest .poi {
  background-image: none !important;
  background-color: #FFFF00;
  border-color: #000; }
  .high-contrast .point-of-interest .poi::after {
    display: none; }
  .high-contrast .point-of-interest .poi span {
    color: #000; }
    .high-contrast .point-of-interest .poi span:after {
      border: 1px #000 solid;
      background-color: transparent !important; }
  .high-contrast .point-of-interest .poi h3,
  .high-contrast .point-of-interest .poi p {
    color: #000 !important; }

.high-contrast #contact-form {
  margin: 2rem auto !important; }
  .high-contrast #contact-form .turistic-contacts .contact:not(:last-child) {
    border-color: #FFFF00; }
  .high-contrast #contact-form .form-box {
    background-color: #000;
    border: 1px #FFFF00 solid; }
    .high-contrast #contact-form .form-box form:before {
      background-color: #FFFF00; }
    .high-contrast #contact-form .form-box form .gdpr-box {
      background-color: #000;
      border-top: 1px #FFFF00 solid; }

.high-contrast #edited_by {
  margin: 1rem 0 !important; }
  .high-contrast #edited_by .row > div {
    color: #FFFF00;
    background-color: #000;
    border: 1px #FFFF00 solid; }

.high-contrast #article-content .description-article {
  color: #FFFF00; }

.high-contrast #article-content .content-media a:after {
  filter: invert(1) brightness(1) sepia(1) saturate(1000%) hue-rotate(-10deg); }

.high-contrast #article-content .content-media img {
  display: none; }

.high-contrast #article-content .content-text img {
  display: none; }

.high-contrast #article-content .gallery-container {
  display: none; }

.high-contrast .article-breadcrumb {
  border-color: #FFFF00; }
  .high-contrast .article-breadcrumb nav {
    color: #FFFF00;
    background-color: #000;
    border-color: #FFFF00; }
    .high-contrast .article-breadcrumb nav ol {
      color: #FFFF00;
      background-color: #000; }
      .high-contrast .article-breadcrumb nav ol .breadcrumb-item + .breadcrumb-item:before {
        color: #FFFF00; }

.high-contrast .rp4wp-related-post .rp4wp-posts-list li {
  color: #000 !important;
  background-color: #FFFF00;
  border-color: #000; }
  .high-contrast .rp4wp-related-post .rp4wp-posts-list li:before {
    background-image: linear-gradient(rgba(255, 255, 0, 0) 5%, #FFFF00 85%); }
  .high-contrast .rp4wp-related-post .rp4wp-posts-list li .rp4wp_component_title a {
    color: #000 !important; }
  .high-contrast .rp4wp-related-post .rp4wp-posts-list li .rp4wp_component_excerpt p {
    color: #000 !important; }

.high-contrast #filters {
  background-color: #000; }
  @media (max-width: 991.98px) {
    .high-contrast #filters {
      border-top: 1px solid #FFFF00; } }
  .high-contrast #filters #filter-show {
    background-color: #000;
    border-color: #FFFF00; }
    .high-contrast #filters #filter-show i:before {
      border-bottom-color: #FFFF00 !important; }
  .high-contrast #filters .filter-input input:checked + span:after {
    filter: invert(1) brightness(2) sepia(1) saturate(1000%) hue-rotate(0deg); }
  .high-contrast #filters .filter-input span:after {
    filter: brightness(0); }
  .high-contrast #filters .filter-input:hover {
    color: #000 !important;
    background-color: #FFFF00;
    border-color: #FFFF00; }
    .high-contrast #filters .filter-input:hover span {
      color: #000 !important; }
      .high-contrast #filters .filter-input:hover span:after {
        opacity: 1;
        filter: brightness(0); }
    .high-contrast #filters .filter-input:hover input:checked + span:after {
      filter: invert(1) brightness(0) sepia(1) saturate(1000%) hue-rotate(0deg);
      border: 1px solid #000;
      border-radius: 50%;
      padding: 0.1em;
      background-size: 70%; }
  .high-contrast #filters .filters-actions .btn-delete {
    background-color: #000;
    border: 1px solid #FFFF00; }
  .high-contrast #filters .filters-actions .btn-apply {
    color: #000;
    background-color: #FFFF00; }
    .high-contrast #filters .filters-actions .btn-apply i {
      color: #000 !important; }

.high-contrast #vam-posts .result .result-count {
  color: #FFFF00; }

.high-contrast #vam-posts .result .result-active-filters button {
  border-color: #FFFF00; }
  .high-contrast #vam-posts .result .result-active-filters button:hover, .high-contrast #vam-posts .result .result-active-filters button:active, .high-contrast #vam-posts .result .result-active-filters button:focus {
    color: #000 !important;
    background-color: #FFFF00;
    border-color: #FFFF00; }
    .high-contrast #vam-posts .result .result-active-filters button:hover i, .high-contrast #vam-posts .result .result-active-filters button:active i, .high-contrast #vam-posts .result .result-active-filters button:focus i {
      color: #000 !important; }

.high-contrast #vam-posts article {
  margin-bottom: 1rem; }
  .high-contrast #vam-posts article:nth-of-type(even) {
    margin-top: inherit; }

.high-contrast #vam-posts .item .featured-image {
  border: 1px #FFFF00 solid;
  background-image: none !important; }
  .high-contrast #vam-posts .item .featured-image span {
    color: #FFFF00; }
    .high-contrast #vam-posts .item .featured-image span:after {
      border: 1px #FFFF00 solid;
      background-color: transparent !important; }

.high-contrast #vam-posts .item .content {
  border: 1px #FFFF00 solid;
  border-top: none;
  background-color: #000 !important; }

.high-contrast #quotation .container {
  background-color: transparent !important; }
  .high-contrast #quotation .container .title {
    color: #FFFF00; }

.high-contrast .page-info .tile-info {
  color: #000 !important;
  background-image: none !important;
  background-color: #FFFF00;
  border-color: #000; }
  .high-contrast .page-info .tile-info span {
    color: #000; }
    .high-contrast .page-info .tile-info span:after {
      border: 1px #000 solid;
      background-color: transparent !important; }
  .high-contrast .page-info .tile-info a:after {
    display: none; }
  .high-contrast .page-info .tile-info a .content label {
    color: #FFFF00 !important;
    background-color: #000; }
  .high-contrast .page-info .tile-info a .content h3,
  .high-contrast .page-info .tile-info a .content p {
    color: #000 !important; }

.high-contrast .page-fotogallery #lightgallery,
.high-contrast .page-fotogallery #video-gallery,
.high-contrast .page-videogallery #lightgallery,
.high-contrast .page-videogallery #video-gallery {
  padding: 1rem; }
  .high-contrast .page-fotogallery #lightgallery li,
  .high-contrast .page-fotogallery #video-gallery li,
  .high-contrast .page-videogallery #lightgallery li,
  .high-contrast .page-videogallery #video-gallery li {
    border: 1px #FFFF00 solid !important; }
    .high-contrast .page-fotogallery #lightgallery li:after,
    .high-contrast .page-fotogallery #video-gallery li:after,
    .high-contrast .page-videogallery #lightgallery li:after,
    .high-contrast .page-videogallery #video-gallery li:after {
      filter: invert(1) brightness(1) sepia(1) saturate(1000%) hue-rotate(-10deg); }
    .high-contrast .page-fotogallery #lightgallery li img,
    .high-contrast .page-fotogallery #video-gallery li img,
    .high-contrast .page-videogallery #lightgallery li img,
    .high-contrast .page-videogallery #video-gallery li img {
      visibility: hidden; }

.high-contrast .page-come-arrivare #transport .transport .title {
  border-color: #FFFF00; }

.high-contrast .page-come-arrivare .map {
  display: none; }
