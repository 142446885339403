@font-face{
    font-family:'Trajan';
    src:url("../../dist/fonts/TrajanPro3-Regular.eot");
    src:url("../../dist/fonts/TrajanPro3-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../dist/fonts/TrajanPro3-Regular.woff2") format("woff2"),
    url("../../dist/fonts/TrajanPro3-Regular.woff") format("woff"),
    url("../../dist/fonts/TrajanPro3.otf") format("opentype"),
    url("../../dist/fonts/TrajanPro3-Regular.ttf") format("truetype"),
    url("../../dist/fonts/TrajanPro3-Regular.svg#") format("svg");
    font-weight:400;
    font-style:normal;
    font-stretch:normal
}

@font-face{
    font-family:'Trajan';
    src:url("../../dist/fonts/TrajanPro3-Bold.eot");
    src:url("../../dist/fonts/TrajanPro3-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../dist/fonts/TrajanPro3-Bold.woff2") format("woff2"),
    url("../../dist/fonts/TrajanPro3-Bold.woff") format("woff"),
    url("../../dist/fonts/TrajanPro3.otf") format("opentype"),
    url("../../dist/fonts/TrajanPro3-Bold.ttf") format("truetype"),
    url("../../dist/fonts/TrajanPro3-Bold.svg#") format("svg");
    font-weight:700;
    font-style:bold;
    font-stretch:normal
}
